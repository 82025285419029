import React from "react";
import { Skeleton, Grid, Card, Text, Flex } from "@mantine/core";

export default function TvReacSkeleton() {
  return (
    <>
      <Card style={{ margin: "20 auto" }}>
        <Card.Section style={{ padding: 20 }}>
          <Grid>
            <Grid.Col span={3} className="mt-5" style={{ paddingBottom: 10 }}>
              <Skeleton height={100} />
            </Grid.Col>
            <Grid.Col span={3} className="mt-5" style={{ paddingBottom: 10 }}>
              <Skeleton height={100} />
            </Grid.Col>
            <Grid.Col span={3} className="mt-5" style={{ paddingBottom: 10 }}>
              <Skeleton height={100} />
            </Grid.Col>
            <Grid.Col span={3} className="mt-5" style={{ paddingBottom: 10 }}>
              <Skeleton height={100} />
            </Grid.Col>
          </Grid>
          <Grid>
            <Grid.Col span={3} className="mt-10">
              <Skeleton height={100} />
            </Grid.Col>
            <Grid.Col span={3} className="mt-10">
              <Skeleton height={100} />
            </Grid.Col>
            <Grid.Col span={3} className="mt-10">
              <Skeleton height={100} />
            </Grid.Col>
            <Grid.Col span={3} className="mt-10">
              <Skeleton height={100} />
            </Grid.Col>
          </Grid>
        </Card.Section>
      </Card>

      <Card style={{ marginTop: 20 }}>
        <Card.Section style={{ padding: 10 }}>
          <Grid justify="space-between">
            <Grid.Col span={6} className="mt-10" style={{ padding: "15px" }}>
              <Skeleton height={500} className="p-5" />
            </Grid.Col>
            <Grid.Col span={6} className="mt-10" style={{ padding: "15px" }}>
              <Skeleton height={500} />
            </Grid.Col>
            <Grid.Col span={6} className="mt-5" style={{ padding: "15px" }}>
              <Skeleton height={500} />
            </Grid.Col>
          </Grid>
        </Card.Section>
      </Card>
    </>
  );
}
