import Tlogo from "../../assets/images/Tlogo.svg";
import { useDisclosure } from "@mantine/hooks";
import { Button, Grid, TextInput, PasswordInput } from "@mantine/core";
import { useNavigate, Link } from "react-router-dom";
import { useState } from "react";
import { setLogin } from "../services/authSlice";
import "./login.scss";
import login from "./Login.generated";
import Captcha from "./Captcha";
import { EyeIcon, EyeSlashIcon } from "@heroicons/react/24/outline";
import { useDispatch } from "react-redux";
import { getrealms } from "../services/realmRoles/realmRoles";

export const LoginForm = () => {
  const [visible, { toggle }] = useDisclosure(false);
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [invalidEmail, setInvalidEmail] = useState<any>(false);
  const [validCaptcha, setValidCaptcha] = useState(false);
  const [captchaInputChecked, setCaptchaInputChecked] = useState(false);
  const [unauthorizedPage, setUnauthorizedPage] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const obj: any = window.location.href.includes("test");

  const loginButtonHandler = async (event: any) => {
    event.preventDefault();
    if (obj === false) {
      if (validCaptcha) {
        login(
          userName,
          password,
          (res: any) => {
            dispatch(setLogin({ userName }));
            sessionStorage.setItem("accesstoken", res.data.accessToken);
            sessionStorage.setItem("refreshtoken", res.data.refreshToken);
            sessionStorage.setItem("data-source", `${res?.data?.realmRoles}`);
            dispatch(getrealms(res?.data?.realmRoles));
            if (
              res.data.realmRoles.includes("INSIGHTS") === false &&
              res.data.realmRoles.includes("AUDIENCE") === false
            ) {
              setUnauthorizedPage(true);
              sessionStorage.setItem("unauthorized", "true");
              navigate("/unauthorized");
            } else {
              sessionStorage.setItem("username", userName);
              sessionStorage.setItem("data-source", `${res?.data?.realmRoles}`);

              dispatch(getrealms(res?.data?.realmRoles));

              res.data.realmRoles.includes("INSIGHTS")
                ? navigate("/trending")
                : navigate("/audiences/audienceDashboard");
              res.data.realmRoles.includes("INSIGHTS") === false
                ? sessionStorage.setItem("insightTab", "false")
                : sessionStorage.setItem("insightTab", "true");
              res.data.realmRoles.includes("AUDIENCE") === false
                ? sessionStorage.setItem("audienceBuilderTab", "false")
                : sessionStorage.setItem("audienceBuilderTab", "true");
              res.data.realmRoles.includes("TV-INSIGHTS") === true
                ? sessionStorage.setItem("tvInsightTab", "true")
                : sessionStorage.setItem("tvInsightTab", "false");
            }
          },
          (err: any) => {
            setErrorMessage("Please enter  valid credentials.");
          }
        );
      } else {
        if (!captchaInputChecked) {
          setErrorMessage("Please enter  Captcha");
        } else {
          setErrorMessage("Please enter  correct Captcha");
        }
      }
    } else {
      login(
        userName,
        password,
        (res: any) => {
          dispatch(setLogin({ userName }));
          sessionStorage.setItem("accesstoken", res.data.accessToken);
          sessionStorage.setItem("refreshtoken", res.data.refreshToken);
          sessionStorage.setItem("username", userName);
          dispatch(getrealms(res.data.realmRoles));
          sessionStorage.setItem("data-source", `${res?.data?.realmRoles}`);
          res.data.realmRoles.includes("INSIGHTS")
            ? navigate("/trending")
            : navigate("/audiences/audienceDashboard");
          res.data.realmRoles.includes("INSIGHTS") === false
            ? sessionStorage.setItem("insightTab", "false")
            : sessionStorage.setItem("insightTab", "true");
          res.data.realmRoles.includes("AUDIENCE") === false
            ? sessionStorage.setItem("audienceBuilderTab", "false")
            : sessionStorage.setItem("audienceBuilderTab", "true");
          res.data.realmRoles.includes("TV-INSIGHTS") === true
            ? sessionStorage.setItem("tvInsightTab", "true")
            : sessionStorage.setItem("tvInsightTab", "false");
        },
        (err: any) => {
          setErrorMessage("Please enter  valid credentials.");
        }
      );
    }
  };
  const userNameHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (errorMessage) {
      setErrorMessage("");
    }
    setUserName(event.target.value);
    setInvalidEmail(false);
  };
  const passwordHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (errorMessage) {
      setErrorMessage("");
    }
    setPassword(event.target.value);
  };
  const validateEmail = () => {
    const validEmailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
    userName.match(validEmailRegex)
      ? setInvalidEmail(false)
      : setInvalidEmail("Please enter a valid email address.");
  };
  const ValidateCaptcha = (captchaVal: boolean) => {
    setValidCaptcha(captchaVal);
  };
  const captchaDirty = (val: boolean) => {
    setCaptchaInputChecked(val);
    setErrorMessage("");
  };

  return (
    <div className="login-background">
      <img src={Tlogo}></img>

      <form className="center-content" onSubmit={loginButtonHandler}>
        <span className="signin-text">Sign in</span>
        <div className="form-container">
          <Grid>
            <Grid.Col className="error">{errorMessage}</Grid.Col>
          </Grid>
          <TextInput
            label="Work email"
            placeholder="Username or email"
            onChange={userNameHandler}
            value={userName}
            error={invalidEmail}
            onBlur={validateEmail}
          ></TextInput>
          <PasswordInput
            label="Password"
            placeholder="Enter your password"
            visible={visible}
            onVisibilityChange={toggle}
            value={password}
            onChange={passwordHandler}
            visibilityToggleIcon={({ reveal }) =>
              reveal ? (
                <EyeIcon height={18} width={18} />
              ) : (
                <EyeSlashIcon height={18} width={18} />
              )
            }
          />
          <Captcha
            isCaptchaValid={ValidateCaptcha}
            isCaptchaInputChecked={captchaDirty}
          />

          <Link to={"/resetpassword"} className="forgot-password">
            Forgot Password?
          </Link>
          <Button
            type="submit"
            fullWidth
            className={
              password.trim().length > 0 &&
              userName.trim().length > 0 &&
              !invalidEmail
                ? "backgrnd-magenta sign-in-btn"
                : "disable-signin sign-in-btn"
            }
            disabled={
              password.trim().length === 0 && userName.trim().length === 0
            }
            data-testid="sign-in-button"
          >
            Sign In
          </Button>
          <div className="sign-in card ">
            <p>
              Not registered yet?{" "}
              <Link to={"/register"} className="forgot-password">
                Sign up now
              </Link>
            </p>
          </div>
        </div>
      </form>
    </div>
  );
};
