import { Grid, Text, Button, Flex } from "@mantine/core";
import { PlusIcon, ExclamationTriangleIcon } from "@heroicons/react/24/solid";
import {
  selectSuggestionAppList,
  setSuggestionArray,
  setSuggestedCategory,
} from "@/features/services/search/searchAppSlice";
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { selectedPrimaryApp } from "@/features/services/overView/overViewSlice";
import { useGetAllCategoriesQuery } from "../../../features/services/trendingApps/trendingAppApiSlice";
import "./SuggestedApps.scss";
import SingleSelectDropDown from "@/features/common/Dropdown/SingleSelectDropDown";
import { selectDateRange } from "@/features/services/dateRange/dateRangeSlice";
import { useLazyGetAppsForSelectedCategoryDataQuery } from "../../../features/services/search/serachAppApiSlice";
import { filterAppandReturnListWithCategory1 } from "@/Util/utils";

const SuggestedApps = (props: any) => {
  const dispatch = useDispatch();
  const suggestionData = useSelector(selectSuggestionAppList);
  const [data, setData] = useState<any>([]);
  const primaryApp = useSelector(selectedPrimaryApp);
  const [showSuggestion, setShowSuggestion] = useState<any>(null);
  const [fetchSuggestedApp] = useLazyGetAppsForSelectedCategoryDataQuery();
  const dateRange = useSelector(selectDateRange);
  const [suggestionLabelFlag, setSuggestionLabelFlag] = useState(false);
  const [categoryValue, setCategoryValue] = useState("");

  const {
    data: categories,
    isError: categoriesErr,
    error: categoriesErrMessage,
  } = useGetAllCategoriesQuery("");
  useEffect(() => {
    if (primaryApp) {
      setData(suggestionData?.slice(0, 5));
      if (suggestionData?.length === 0) {
        setShowSuggestion(true);
      } else {
        setShowSuggestion(false);
      }
    }
  }, [suggestionData, props, primaryApp, setShowSuggestion]);

  const handleClick = (item: any) => {
    props.onclick(item);
  };
  const getSuggestedAppList = (val: string) => {
    setSuggestionLabelFlag(true);
    setCategoryValue(val);
    const payload = {
      trendingAppForSelectedCategory: {
        appCategory: [val],
        fromDate: dateRange?.fromDate,
        toDate: dateRange?.toDate,
      },
    };
    fetchSuggestedApp({
      ...payload,
    }).then((res: any) => {
      if (res?.data) {
        const suggestion = filterAppandReturnListWithCategory1(
          res?.data,
          primaryApp,
          null,
          null
        );
        dispatch(setSuggestionArray(suggestion));
      }
    });
  };
  useEffect(() => {
    if (props.displaySingleSelectValue) {
      setCategoryValue(props.primaryCategoryList[0]);
    } else if (suggestionLabelFlag === true) {
      dispatch(setSuggestedCategory(categoryValue));
      setCategoryValue(categoryValue);
    } else {
      setCategoryValue(props.primaryCategoryList[0]);
    }
  }, [
    suggestionLabelFlag,
    categoryValue,
    props.primaryCategoryList,
    props.displaySingleSelectValue,
  ]);

  const flag: any =
    props?.primaryCategoryList !== undefined &&
    props?.primaryCategoryList !== null &&
    props?.primaryCategoryList !== "";

  return (
    <>
      {flag === false && (
        <Grid>
          <Grid.Col className={"pb-10"}></Grid.Col>
        </Grid>
      )}
      {flag === true && (
        <Grid className="suggested-component">
          <Grid.Col className={"pb-10"}>
            {data !== null && data?.length > 0 && (
              <Text
                size="xs"
                className="mt-2 text-secondary"
                style={{ display: "flex", alignItems: "baseline", gap: "20px" }}
              >
                <>
                  {suggestionLabelFlag === false
                    ? `Suggested Apps Based on Primary App Category:`
                    : `Suggested Apps Based on Category:`}
                </>
                <SingleSelectDropDown
                  data={categories}
                  value={categoryValue}
                  updatedList={getSuggestedAppList}
                  disabled={props.primaryApp && props.app2 && props.app3}
                />
              </Text>
            )}
            <Flex align={"center"}>
              {data?.length > 0 &&
                data?.map((item: any) => {
                  return (
                    <div
                      style={{
                        cursor:
                          props.primaryApp && props.app2 && props.app3
                            ? "no-drop"
                            : "pointer",
                      }}
                      key={item.value}
                    >
                      <Button
                        className={
                          props.primaryApp && props.app2 && props.app3
                            ? "disabled primary-button-outline my-3 outline mr-2 cursor-disable"
                            : "primary-button-outline my-3 outline mr-2"
                        }
                        onClick={() => handleClick(item)}
                        key={item.value}
                        disabled={props.primaryApp && props.app2 && props.app3}
                      >
                        <img
                          src={item?.appicon}
                          className="img-fluid"
                          role={item?.appicon}
                        />
                        <Text className="suggestion-text">{item?.value}</Text>
                        {props.primaryApp && props.app2 && props.app3 ? (
                          ""
                        ) : (
                          <PlusIcon className="h-4 w-4" />
                        )}
                      </Button>
                    </div>
                  );
                })}
              {showSuggestion == true && (
                <>
                  <ExclamationTriangleIcon className="w-4 h-4 ml-3 mr-1 mt-3 text-muted" />
                  <Text className="mt-3 text-muted" fz="xs">
                    No Similar Apps found.
                  </Text>
                </>
              )}
            </Flex>
          </Grid.Col>
        </Grid>
      )}
    </>
  );
};

export default SuggestedApps;
