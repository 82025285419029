import { useEffect, useRef, useState } from "react";
import {
  Header,
  Grid,
  Text,
  Image,
  Flex,
  Menu,
  SimpleGrid,
  Popover,
  Switch,
  Group,
} from "@mantine/core";
import { useSelector, useDispatch } from "react-redux";

import { NavLink, useNavigate } from "react-router-dom";
import {
  HomeIcon,
  SunIcon,
  MoonIcon,
  ArrowLeftOnRectangleIcon,
  UsersIcon,
} from "@heroicons/react/24/solid";
import { ChartBarIcon, ChevronDownIcon } from "@heroicons/react/24/outline";
import ProfilePicture from "src/assets/images/profile-picture.png";
import HeaderLogo from "@/assets/images/header-logo.png";
import TvIcon from "@/assets/icons/Tv.svg";
import TvHoveredIcon from "@/assets/icons/Tv.png";
import TvIconDark from "@/assets/icons/TvIconDark.svg";
import "./AppHeader.scss";
import logout from "@/features/login/Logout.genearted";
import {
  setTheme,
  selectLightTheme,
} from "@/features/services/themes/themeSlice";
import { useLocation } from "react-router-dom";

export const AppHeader = (props: any) => {
  const dispatch = useDispatch();
  const themeProvider = useSelector(selectLightTheme);
  const location = useLocation();

  const navigate = useNavigate();
  const myRef = useRef<any | null>(null);
  const homeRef = useRef<any | null>(null);
  const audienceRef = useRef<any | null>(null);
  const tvRef = useRef<any | null>(null);
  const [checked, setChecked] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const handleMouseEnter = () => setIsHovered(true);
  const handleMouseLeave = () => setIsHovered(false);

  useEffect(() => {
    if (location?.pathname.includes("insights") && myRef !== null) {
      myRef?.current?.classList?.add("active1");
    } else if (
      (location?.pathname?.includes("trending") ||
        location?.pathname?.includes("appProfile")) &&
      homeRef !== null
    ) {
      homeRef?.current?.classList?.add("active1");
    } else if (
      location?.pathname.includes("audiences") &&
      audienceRef !== null
    ) {
      audienceRef?.current?.classList?.add("active1");
    } else if (location?.pathname?.includes("tv") && tvRef !== null) {
      tvRef?.current?.classList?.add("active1");
    } else {
      homeRef?.current?.classList?.remove("active1");
      myRef?.current?.classList?.remove("active1");
      audienceRef?.current?.classList?.remove("active1");
      tvRef?.current?.classList?.remove("active1");
    }
  }, [myRef, location, homeRef, audienceRef, tvRef]);

  const logoutHandler = () => {
    logout(
      () => {
        navigate("/");
        sessionStorage.removeItem("accesstoken");
        sessionStorage.removeItem("refreshtoken");
        sessionStorage.removeItem("username"); //insightTab audienceBuilderTab
        sessionStorage.removeItem("insightTab");
        sessionStorage.removeItem("audienceBuilderTab");
        sessionStorage.removeItem("admin");
        sessionStorage.removeItem("emetriqLogin");
        sessionStorage.removeItem("tvInsightTab");
        sessionStorage.removeItem("data-source");
        sessionStorage.removeItem("theme");
      },
      () => {}
    );
  };
  const handleChange = (event: any) => {
    setChecked(event.currentTarget.checked);
    if (event.currentTarget.checked === true) {
      sessionStorage.setItem("theme", "dark");
      dispatch(setTheme("dark"));
    } else {
      dispatch(setTheme("light"));
      sessionStorage.setItem("theme", "dark");
    }
  };

  useEffect(() => {
    if (themeProvider === "dark") {
      setChecked(true);
      sessionStorage.setItem("theme", "dark");
    } else {
      setChecked(false);
      sessionStorage.setItem("theme", "light");
    }
  }, [themeProvider, setChecked, sessionStorage]);
  const accessManagemnt = () => {
    navigate("/home/accessmanagment");
  };
  const appOverview = () => {
    navigate("/home/appOverview");
  };

  return (
    <Header height="auto" className="app-header">
      <Grid gutter={0} align="center">
        <Grid.Col lg={8} md={7}>
          <Group spacing="sm" className="header-group">
            <Image width={30} src={HeaderLogo} className="divider" />
            <Text fz="md" className="color-magenta divider">
              Magenta Advantage
              <Text fz="xs">Advertising Platform</Text>
            </Text>
            {/* <Divider orientation="vertical" size="sm" /> */}
            <Group spacing={0}>
              <NavLink to="/trending" ref={homeRef}>
                <Flex align={"center"}>
                  <HomeIcon className="h-6 w-6 mr-2" />
                  Home
                </Flex>
              </NavLink>
              {sessionStorage.getItem("insightTab") &&
                sessionStorage.getItem("insightTab") === "true" && (
                  <NavLink to="" ref={myRef}>
                    <Popover
                      width={200}
                      position="bottom"
                      withArrow
                      shadow="md"
                    >
                      <Popover.Target>
                        <Flex align={"center"}>
                          <ChartBarIcon className="h-6 w-6 mr-2" />
                          Insights
                          <ChevronDownIcon className="h-4 w-4 ml-2" />
                        </Flex>
                      </Popover.Target>
                      <Popover.Dropdown>
                        <SimpleGrid
                          cols={1}
                          spacing={0}
                          className="header-sub-menu"
                        >
                          <NavLink to="/insights/competitiveResearch">
                            Competitive Research
                          </NavLink>
                          <NavLink to="/insights/NetReachReport">
                            Net-Reach Report
                          </NavLink>
                          <NavLink to="" className="hidden">
                            Saved Insights
                          </NavLink>
                        </SimpleGrid>
                      </Popover.Dropdown>
                    </Popover>
                  </NavLink>
                )}
              {sessionStorage.getItem("audienceBuilderTab") &&
                sessionStorage.getItem("audienceBuilderTab") === "true" && (
                  <NavLink to="/audiences/audienceDashboard" ref={audienceRef}>
                    <Flex align={"center"}>
                      <UsersIcon className="h-6 w-6 mr-2" />
                      Audiences
                    </Flex>
                  </NavLink>
                )}
              {sessionStorage.getItem("tvInsightTab") &&
                sessionStorage.getItem("tvInsightTab") === "true" && (
                  <NavLink
                    to="/tv/tvDashboard"
                    ref={tvRef}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                  >
                    <Flex align={"center"}>
                      <Image
                        width={30}
                        src={
                          isHovered === true
                            ? TvHoveredIcon
                            : themeProvider === "dark"
                            ? TvIconDark
                            : TvIcon
                        }
                        className="divider"
                      />
                      TV Insights
                    </Flex>
                  </NavLink>
                )}
            </Group>
          </Group>
        </Grid.Col>
        <Grid.Col lg={4} md={4}>
          <Flex justify="flex-end" align="center" direction="row">
            <SimpleGrid mr={10}>
              <Switch
                checked={checked}
                onChange={handleChange}
                size="lg"
                color={props.colors}
                onLabel={<SunIcon className="w-4 h-4" color={props.lightBg} />}
                offLabel={<MoonIcon className="w-4 h-4" color={props.darkBg} />}
              />
            </SimpleGrid>
            <Menu shadow="md" position="bottom-end" offset={7}>
              <Menu.Target>
                <Image width={30} src={ProfilePicture} className="mr-6" />
              </Menu.Target>
              <Menu.Dropdown className="dropdown-menu">
                <Menu.Item>{sessionStorage.getItem("username")}</Menu.Item>
                {sessionStorage.getItem("admin") === "true" && (
                  <Menu.Item onClick={accessManagemnt}>
                    <Flex align={"center"}>
                      <Text>Access Management</Text>
                    </Flex>
                  </Menu.Item>
                )}
                {(window.location.href.includes("dev") ||
                  window.location.href.includes("localhost")) &&
                  sessionStorage.getItem("admin") === "true" && (
                    <Menu.Item onClick={appOverview}>
                      <Flex align={"center"}>
                        <Text>App Overview Dashboard</Text>
                      </Flex>
                    </Menu.Item>
                  )}
                {/* <Menu.Item>Reset Passowrd</Menu.Item> */}
                <Menu.Item onClick={logoutHandler}>
                  <Flex align={"center"}>
                    <ArrowLeftOnRectangleIcon className="w-4 h-4 mr-1" />
                    <Text>Logout</Text>
                  </Flex>
                </Menu.Item>
              </Menu.Dropdown>
            </Menu>
          </Flex>
        </Grid.Col>
      </Grid>
    </Header>
  );
};

export default AppHeader;
