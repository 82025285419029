import {
  EyeIcon,
  HomeIcon,
  MegaphoneIcon,
  UserGroupIcon,
} from "@heroicons/react/24/solid";
import { Box, Flex, Grid, Text } from "@mantine/core";
import { useState } from "react";

export default function GrpCard(props: any) {
  const [showActiveHouseHold, setActiveHouseHold] = useState(false);
  return (
    <>
      <Grid.Col span={3}>
        <Box
          sx={(theme) => ({
            textAlign: "center",
            padding: theme.spacing.md,
            borderRadius: theme.radius.sm,
            cursor: "pointer",
            border: "1px solid rgba(198, 208, 224, 1)",
            height: "80px",
          })}
        >
          <Flex>
            <UserGroupIcon className="h-6 w-6 mr-4 mt-2" />
            <Flex direction="column" className="text-left">
              <Text>{props.netReachPercent + "%"}</Text>
              <Flex>
                <Text fz="xs" className="text-secondary mr-1">
                  Net Reach
                </Text>
              </Flex>
            </Flex>
          </Flex>
        </Box>
      </Grid.Col>
      <Grid.Col span={3}>
        <Box
          sx={(theme) => ({
            textAlign: "center",
            padding: theme.spacing.md,
            borderRadius: theme.radius.sm,
            cursor: "pointer",
            border: "1px solid rgba(198, 208, 224, 1)",
            height: "80px",
          })}
        >
          <Flex>
            <MegaphoneIcon className="h-6 w-6 mr-4 mt-2" />
            <Flex direction="column" className="text-left">
              <Text>{props.totalMediaCost}</Text>
              <Flex>
                <Text fz="xs" className="text-secondary mr-1">
                  Media Gross
                </Text>
              </Flex>
            </Flex>
          </Flex>
        </Box>
      </Grid.Col>
      <Grid.Col span={3}>
        <Box
          sx={(theme) => ({
            textAlign: "center",
            padding: theme.spacing.md,
            borderRadius: theme.radius.sm,
            cursor: "pointer",
            border: "1px solid rgba(198, 208, 224, 1)",
            height: "80px",
          })}
        >
          <Flex>
            <HomeIcon className="h-6 w-6 mr-4 mt-2" />
            <Flex direction="column" className="text-left">
              <Text>{props.totalKontakt}</Text>
              <Flex>
                <Text fz="xs" className="text-secondary mr-1">
                  Household (Magenta TV)
                </Text>
              </Flex>
            </Flex>
          </Flex>
        </Box>
      </Grid.Col>
      <Grid.Col span={3}>
        <Box
          sx={(theme) => ({
            textAlign: "center",
            padding: theme.spacing.md,
            borderRadius: theme.radius.sm,
            cursor: "pointer",
            border: "1px solid rgba(198, 208, 224, 1)",
            height: "80px",
          })}
        >
          <Flex>
            <EyeIcon className="h-6 w-6 mr-4 mt-2" />
            <Flex direction="column" className="text-left" justify={"center"}>
              {showActiveHouseHold && (
                <Text>{props.activeHouseholdsCount}</Text>
              )}
              <Flex>
                <Text
                  fz={showActiveHouseHold ? "sm" : "md"}
                  className={
                    showActiveHouseHold === true
                      ? "text-secondary mr-1"
                      : "text-primary mr-1"
                  }
                  style={{ textDecoration: "underline" }}
                  onClick={() => setActiveHouseHold(!showActiveHouseHold)}
                >
                  View Baseline
                </Text>
              </Flex>
            </Flex>
          </Flex>
        </Box>
      </Grid.Col>
    </>
  );
}
