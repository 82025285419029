import { apiSlice } from "../api/apiSlice";
import { gql } from "graphql-request";

const getBlacklistDataQuery = gql`
  query getApplicationsList {
    getApplicationsList {
      allowedApps {
        appName
        categories
      }
      blacklistedApps {
        appName
        categories
      }
    }
  }
`;

export const blackListDataSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getBlackListData: builder.query<any, any>({
      query: (variables: any): any => ({
        document: getBlacklistDataQuery,
      }),
      transformResponse: (response: any): any => {
        return response;
      },
    }),
  }),
});

export const { useGetBlackListDataQuery, useLazyGetBlackListDataQuery } =
  blackListDataSlice;
