import TrendingAppsDashboard from "@/Pages/Dashboard/TrendingAppsDashboard";
import AppProfileDashboard from "@/Pages/AppDashboard/AppProfileDashboard";
import Insights from "@/Pages/Insights/Insights";
import { LoginLanding } from "@/features/login/LoginLanding";
import { LoginForm } from "@/features/login/LoginForm";
import { ForgotPassword } from "@/features/login/ForgotPassword";
import { ResetEmailSuccess } from "@/features/login/ResetEmailSuccess";
import AccessManagemnt from "@/features/login/AccessManagment";
import {
  createBrowserRouter,
  Route,
  createRoutesFromElements,
} from "react-router-dom";
import LoginRegistration from "@/features/login/LoginRegistration";
import ProtectedRoutes from "./ProtectedRoutes";
import { ForgotPasswordForm } from "@/features/login/ForgotPasswordForm";
import NetReachReport from "@/Pages/NetReachReport/NetReachReport";
import CreateAudience from "@/Pages/PersonaBuilder/createAudience/CreateAudience";
import AudienceResults from "@/Pages/PersonaBuilder/createAudience/AudienceResults";
import AudienceDashboard from "@/Pages/PersonaBuilder/AudienceDashboard";
import OtpVerificationPage from "@/features/login//OtpVerificationPage";
import UnauthorizedPage from "@/features/common/UnauthorizedPage";
import TVDashboard from "@/Pages/tv/TVDashboard";
import AppBlacklistDashboard from "@/Pages/AppBlacklist/AppBlacklistDashboard";
const AppRouter = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path="/" element={<LoginLanding />} />
      <Route path="/login" element={<LoginForm />} />
      <Route path="/register" element={<LoginRegistration />} />
      <Route path="/resetpassword" element={<ForgotPassword />} />
      <Route path="/resetemailsuccess" element={<ResetEmailSuccess />} />
      <Route path="/forgotpasswordform" element={<ForgotPasswordForm />} />
      <Route path="/otpverifcationpage" element={<OtpVerificationPage />} />
      <Route path="unauthorized" element={<UnauthorizedPage />} />
      <Route
        path="/trending"
        element={
          <ProtectedRoutes>
            <TrendingAppsDashboard />
          </ProtectedRoutes>
        }
      />
      <Route
        path="/appProfile/:id"
        element={
          <ProtectedRoutes>
            <AppProfileDashboard />
          </ProtectedRoutes>
        }
      />
      <Route
        path="/insights/competitiveResearch"
        element={
          <ProtectedRoutes>
            <Insights />
          </ProtectedRoutes>
        }
      />
      <Route
        path="/insights/netReachReport"
        element={
          <ProtectedRoutes>
            <NetReachReport />
          </ProtectedRoutes>
        }
      />
      <Route
        path="/audiences/audienceDashboard"
        element={
          <ProtectedRoutes>
            <AudienceDashboard />
          </ProtectedRoutes>
        }
      />
      <Route
        path="/tv/TVDashboard"
        element={
          <ProtectedRoutes>
            <TVDashboard />
          </ProtectedRoutes>
        }
      />
      <Route
        path="/audiences/create"
        element={
          <ProtectedRoutes>
            <CreateAudience />
          </ProtectedRoutes>
        }
      />
      <Route
        path="/audiences/results"
        element={
          <ProtectedRoutes>
            <AudienceResults />
          </ProtectedRoutes>
        }
      />
      <Route
        path="/home/accessmanagment"
        element={
          <ProtectedRoutes>
            <AccessManagemnt />
          </ProtectedRoutes>
        }
      />
      <Route
        path="/home/appOverview"
        element={
          <ProtectedRoutes>
            <AppBlacklistDashboard />
          </ProtectedRoutes>
        }
      />
      <Route path="*" element={<LoginLanding />} />
    </>
  )
);
export default AppRouter;
