import React, { useEffect, useRef } from "react";
import ReactECharts from "echarts-for-react";
import { useSelector } from "react-redux";
import { selectLightTheme } from "@/features/services/themes/themeSlice";
import { numberFormatter } from "@/Util/utils";

interface LineBarChartProps {
  height?: string;
  data: any[];
  chartName: string;
  xField: string;
  yPrefix?: string;
  lineLabels: [string, string][];
  barLabels: [string, string][];
  colors?: string[];
  xLabelRotation?: number;
}

const LineBarChart: React.FC<LineBarChartProps> = ({
  height = "300px",
  data,
  chartName,
  xField,
  yPrefix = "",
  lineLabels = [["Value", "value"]],
  barLabels = [["Value", "value"]],
  colors = ["#02748b", "#02748b"],
  xLabelRotation = 0,
}) => {
  const chartRef = useRef<any>(null);
  const themeProvider = useSelector(selectLightTheme);

  useEffect(() => {
    if (chartRef.current) {
      chartRef.current.getEchartsInstance().resize();
    }
  }, [data]);

  const getOption = () => {
    const series = [
      ...barLabels.map(([name, field]) => ({
        name,
        type: "bar",
        data: data.map((item) => item[field]),
        itemStyle: {
          color: colors.length === 1 ? colors[0] : undefined,
        },
      })),
      ...lineLabels.map(([name, field]) => ({
        name,
        type: "line",
        data: data.map((item) => item[field]),
        yAxisIndex: 1,
        itemStyle: {
          color: colors.length === 1 ? colors[1] : undefined,
        },
      })),
    ];

    return {
      grid: {
        containLabel: true,
        x: 30,
        y: 30,
        x2: 30,
        y2: 50,
      },
      title: {
        text: "",
        itemStyle: {
          display: "none",
        },
        textStyle: {
          color: themeProvider === "light" ? "#00022d" : "#ffffff",
        },
      },
      tooltip: {
        trigger: "axis",
        formatter: (params: any) => {
          const items = params.map(
            (item: any) => `${item.seriesName}: ${item.data}`
          );
          return `${params[0].name}<br/>${items.join("<br/>")}`;
        },
      },
      legend: {
        data: [
          ...barLabels.map(([name]) => name),
          ...lineLabels.map(([name]) => name),
        ],
        textStyle: {
          color: themeProvider === "light" ? "#00022d" : "#ffffff",
        },
      },
      xAxis: {
        type: "category",
        data: data.map((item) => item[xField]),
        axisLabel: {
          rotate: xLabelRotation,
          textStyle: {
            color: themeProvider === "light" ? "#00022d" : "#ffffff",
          },
        },
      },
      yAxis: [
        {
          type: "value",
          axisLabel: {
            formatter: (value: any) => `${yPrefix}${numberFormatter(value)}`,
            textStyle: {
              color: themeProvider === "light" ? "#00022d" : "#ffffff",
            },
          },
        },
        {
          type: "value",
          axisLabel: {
            formatter: (value: any) => `${value}%`,
            textStyle: {
              color: themeProvider === "light" ? "#00022d" : "#ffffff",
            },
          },
        },
      ],
      dataZoom: [
        {
          type: "slider",
          start: 0,
          end: 100,
          xAxisIndex: 0,
          yAxisIndex: 100,
          zoomLock: false,
        },
        {
          type: "inside",
          start: 0,
          end: 100,
          xAxisIndex: 0,
          yAxisIndex: 100,
          zoomLock: false,
        },
      ],
      series,
      color: colors.length > 1 ? colors : undefined,
    };
  };

  return (
    <ReactECharts
      ref={chartRef}
      option={getOption()}
      style={{ height }}
      notMerge={true}
      lazyUpdate={true}
    />
  );
};

export default LineBarChart;
