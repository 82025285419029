import { createSlice } from "@reduxjs/toolkit";

interface InitialState {
  spot: [] | any ;
  initialAppArray: [] | any;
  imageArr: [] | any
  spotIdArr: any;
}

export const initialState: InitialState = {
  spot: [],
  initialAppArray: [],
  imageArr: [],
  spotIdArr: []
};

const tvReachSlice = createSlice({
  name: "tvreach",
  initialState: { ...initialState },
  reducers: {
    setImageArr: (state, action) => {
        state.imageArr = action.payload
    },
    setInitialAppArray: (state, action) => {
        state.initialAppArray = action.payload;
    },
    setSpotData: (state, action) => {
      state.spot = action.payload;
    },
    setSpotIdArray: (state, action) => {
      state.spotIdArr = action.payload;
    }
  },
});

export const {
    setImageArr,
    setInitialAppArray,
    setSpotData,
    setSpotIdArray
} = tvReachSlice.actions;

export default tvReachSlice.reducer;

export const selectImageArr = (state:any) => state.tvreach.imageArr;

export const selectedInitialAppArray = (state: any) => state.tvreach.initialAppArray;

export const selectedSportArray = (state: any) => state.tvreach.spot;

export const selectspotIdArr = (state:any) => state.tvreach.spotIdArr
