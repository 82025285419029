import { Button, Modal, Text, Loader } from "@mantine/core";

export default function LoadingModal(props: any) {
  return (
    <Modal
      opened={props.opened}
      onClose={props.close}
      withCloseButton={false}
      centered
    >
      <div
        style={{
          height: "150px",
          justifyContent: "center",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Text>Please wait, Loading is about to complete...</Text>
        <Button className="secondary-button my-3" style={{ display: "flex" }}>
          <Loader variant="bars" size="sm" color="#02748b" className="mr-3" />
          <Text>Cancel Loading</Text>
        </Button>
      </div>
    </Modal>
  );
}
