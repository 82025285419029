import { AppShell } from "@mantine/core";
import { RouterProvider, Navigate } from "react-router-dom";
import AppRouter from "./AppRouter";
import { useEffect, useState } from "react";
import { useIdleTimer } from "react-idle-timer";
import logout from "@/features/login/Logout.genearted";
import refresh from "@/features/login/RefreshToken.generated";
import { notifications } from "@mantine/notifications";
import { useSelector, useDispatch } from "react-redux";
import { selectMessage, setError } from "../features/services/errorSlice";
import "./App.scss";

export const App = (props: any) => {
  const [state, setState] = useState<string>("Active");
  const [remaining, setRemaining] = useState<number>(0);
  const dispatch = useDispatch();

  const appName = useSelector(selectMessage);
  let errMessage = appName.errorMessage;
  let successMessage = appName.successMessage;
  const onIdle = () => {
    setState("Idle");
    if (sessionStorage.getItem("accesstoken")) {
      logout(
        (res: any) => {
          sessionStorage.removeItem("accesstoken");
          sessionStorage.removeItem("refreshtoken");
          sessionStorage.removeItem("username");
          <Navigate to="/login" replace />;
        },
        (errRes: any) => {}
      );
    }
  };

  const onActive = () => {
    setState("Active");
  };

  const { getRemainingTime } = useIdleTimer({
    onIdle,
    onActive,
    promptBeforeIdle: 500,
    timeout: 30 * 60 * 1000,
    throttle: 500,
  });

  useEffect(() => {
    const interval = setInterval(() => {
      setRemaining(Math.ceil(getRemainingTime() / 1000));
    }, 500);
    return () => {
      clearInterval(interval);
    };
  });
  useEffect(() => {
    if (errMessage && errMessage.length > 0) {
      notifications.show({
        id: "login-sucess",
        withCloseButton: true,
        onClose: () => {
          dispatch(setError(null));
        },
        onOpen: () => {},
        autoClose: 5000,
        title: "ERROR",
        message: errMessage,
        color: "red",
        className: "my-notification-class",
        style: { backgroundColor: "white" },
        sx: { backgroundColor: "red" },
        loading: false,
      });
    }
    if (successMessage && successMessage.length > 0) {
      notifications.show({
        id: "sucess",
        withCloseButton: true,
        onClose: () => {
          dispatch(setError(null));
        },
        onOpen: () => {},
        autoClose: 5000,
        title: "SUCCESS",
        message: successMessage,
        color: "green",
        className: "my-notification-class",
        style: { backgroundColor: "white" },
        sx: { backgroundColor: "red" },
        loading: false,
      });
    }
  }, [errMessage, successMessage]);

  const fetchAccessToken = () => {
    if (sessionStorage.getItem("username")) {
      refresh();
    }
  };
  useEffect(() => {
    const interval = setInterval(
      () => {
        fetchAccessToken();
      },
      29 * 60 * 1000
    );
    return () => {
      clearInterval(interval);
    };
  }, []);
  return (
    <AppShell padding="md" className={props?.className + "-theme"}>
      <RouterProvider router={AppRouter} />
    </AppShell>
  );
};

export default App;
