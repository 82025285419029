import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import {
  ArrowDownTrayIcon,
  CalendarIcon,
  ChevronDownIcon,
} from "@heroicons/react/24/solid";
import {
  Card,
  Flex,
  Grid,
  Select,
  Text,
  Image,
  Divider,
  Popover,
  Button,
  Group,
  Input,
  Checkbox,
  SimpleGrid,
} from "@mantine/core";
import { DatePicker } from "@mantine/dates";
import { useRef } from "react";

export default function Filter(props: any) {
  const buttonClasses = !(
    props.appValue !== null && props?.endDate !== "Invalid date"
  )
    ? "disabled ml-4 mt-7 text-center"
    : "apply-button ml-4 mt-7 text-center";

  const disabled = !(
    props.appValue !== null && props?.endDate !== "Invalid date"
  );
  const today = new Date();
  const minDate = new Date(new Date().setDate(today.getDate() - 90));
  const currentDate = new Date();

  return (
    <Card.Section className="p-4">
      <Text className="text-2xl mb-6">TV Insights</Text>
      <Grid className="tv-dashboard">
        <Grid.Col span={12}>
          <Flex align="center" justify={"space-between"}>
            <Flex align="center">
              <div className="customer-select">
                <Text className="text-secondary text-sm mb-2">
                  Customer
                  <sup className="error">*</sup>
                </Text>
                <div className="tv-reach-select">
                  <div className="tv-react-icon-container">
                    <Image
                      width={25}
                      height={25}
                      src={props.appImage}
                      alt={"frame"}
                    />
                  </div>
                  <Select
                    dropdownPosition="bottom"
                    rightSection={<ChevronDownIcon className="h-4 w-4 ml-2" />}
                    rightSectionWidth={60}
                    styles={{ rightSection: { pointerEvents: "none" } }}
                    data={props?.data}
                    value={props?.appValue}
                    onChange={props.handleAppSelection}
                    searchable
                    withinPortal
                    itemComponent={props.itemComponent}
                    initiallyOpened={props.initiallyOpened}
                    placeholder="Pick one"
                    limit={100}
                    className="customer-select"
                  />
                </div>
              </div>
              <div className="ml-4">
                <Text className="text-secondary text-sm mb-2">Spots</Text>
                <Popover
                  width={300}
                  trapFocus
                  position="bottom-end"
                  withArrow
                  shadow="md"
                  withinPortal
                >
                  <Popover.Target>
                    <Button variant="outline" className="spots-button">
                      <Text fz="sm">
                        {props.selectedSpotIds.length > 0
                          ? props.selectedSpotIds.length + " selected"
                          : "All Spots"}
                      </Text>
                      <ChevronDownIcon className="h-4 w-4 ml-2" />
                    </Button>
                  </Popover.Target>
                  <Popover.Dropdown
                    className="categories-dropdown"
                    sx={(theme) => ({
                      background:
                        theme.colorScheme === "dark"
                          ? theme.colors.dark[7]
                          : theme.white,
                    })}
                  >
                    <Input
                      className="mb-3 mr-4 src-input"
                      icon={
                        <MagnifyingGlassIcon
                          height={20}
                          width={20}
                          color="#6E7B91"
                        />
                      }
                      placeholder="Search "
                      value={props.searchTermCat}
                      onChange={props.handleSearchChangeCat}
                    />
                    {props.filteredSpotIds?.length > 0 && (
                      <Group
                        sx={{
                          alignItems: "start",
                          justifyContent: "space-between",
                        }}
                      >
                        <Checkbox
                          value={"all_categories"}
                          label={"Select All"}
                          className="mb-4 tv-checkbox"
                          onChange={props.handleSelectAll}
                          checked={props.isSelectAll}
                        />
                        <Text
                          style={{
                            fontSize: "14px",
                            textDecoration: "underline",
                          }}
                        >
                          <a
                            style={{ color: "#e20074", cursor: "pointer" }}
                            onClick={props.clearAll}
                          >
                            Clear all
                          </a>
                        </Text>
                      </Group>
                    )}
                    {props.filteredSpotIds?.length > 0 && (
                      <Checkbox.Group
                        value={props.selectedSpotIds}
                        onChange={(e: any) => props.handleClick(e)}
                      >
                        <SimpleGrid>
                          {props.filteredSpotIds?.map((category: any) => {
                            return (
                              <Checkbox
                                value={category.value}
                                label={category.value}
                                key={category.value}
                                className="tv-checkbox"
                              />
                            );
                          })}
                        </SimpleGrid>
                      </Checkbox.Group>
                    )}
                    {props.searchTermCat.length > 0 &&
                      props.filteredSpotIds?.length === 0 && <>No Match !</>}
                  </Popover.Dropdown>
                </Popover>
              </div>
              <Divider orientation="vertical" className="ml-4" />
              <div className="ml-4">
                <Text className="text-secondary text-sm mb-2">
                  Date Range <sup className="error">*</sup>
                </Text>
                <Popover
                  width={600}
                  trapFocus
                  withArrow
                  shadow="md"
                  position="bottom"
                  withinPortal
                >
                  <Popover.Target>
                    <Button
                      size="xs"
                      fullWidth
                      variant="outline"
                      className="date-button"
                    >
                      <CalendarIcon className="h-4 w-4 mr-2" />
                      <Text fz="sm">
                        {props?.startDate !== "Invalid date" &&
                        props?.endDate !== "Invalid date"
                          ? props?.startDate + " - " + props?.endDate
                          : "Select Date Range"}
                      </Text>
                      <ChevronDownIcon className="h-4 w-4 ml-2" />
                    </Button>
                  </Popover.Target>
                  <Popover.Dropdown
                    className="categories-dropdown"
                    sx={(theme) => ({
                      background:
                        theme.colorScheme === "dark"
                          ? theme.colors.dark[7]
                          : theme.white,
                    })}
                  >
                    <Group position="center">
                      <DatePicker
                        type="range"
                        numberOfColumns={2}
                        value={props.value}
                        onChange={props.setValue}
                        maxDate={currentDate}
                        minDate={minDate}
                        styles={() => ({
                          day: {
                            "&[data-selected]": {
                              backgroundColor: "#02748b !important",
                              color: "white",
                            },
                            "&[data-in-range]": {
                              backgroundColor: "#02748b",
                              color: "#ffffff",
                            },
                            "&[data-in-range]:hover": {
                              backgroundColor: "#02748b",
                              color: "#ffffff",
                            },
                          },
                        })}
                      />
                    </Group>
                    <Divider my="sm" />
                  </Popover.Dropdown>
                </Popover>
              </div>
              <div>
                <Button
                  size="sm"
                  radius="md"
                  className={buttonClasses}
                  onClick={props.handleNetReachAndMediaCostData}
                  disabled={disabled}
                >
                  Apply
                </Button>
              </div>
            </Flex>
            <Flex align="center" className="mt-6">
              <Button
                size="sm"
                radius="md"
                variant="outline"
                className={
                  props.isPDFdisabled
                    ? "btn ml-6 disabled"
                    : "btn ml-6 pdf-button"
                }
                style={{ display: "none" }}
                onClick={props.handlePDFClick}
                disabled={props.isPDFdisabled}
              >
                <ArrowDownTrayIcon className="h-4 w-4 mr-2" />
                PDF
              </Button>
            </Flex>
          </Flex>
        </Grid.Col>
      </Grid>
    </Card.Section>
  );
}
