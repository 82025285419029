import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Hoc from "@/Hoc/Hoc";
import {
  Grid,
  Text,
  Image,
  Flex,
  Button,
  Card,
  Checkbox,
  Select,
  Popover,
  SimpleGrid,
  Skeleton,
  Tooltip,
  Input,
  Group,
} from "@mantine/core";
import "./TrendingAppsDashboard.scss";
import {
  ChevronDownIcon,
  InformationCircleIcon,
} from "@heroicons/react/24/outline";
import {
  ArrowDownIcon,
  ArrowRightCircleIcon,
  ArrowUpIcon,
} from "@heroicons/react/24/solid";
import DefaultAppImage from "@/assets/images/default-app.svg";
import moment from "moment";
import {
  useLazyGetTrendingAppsQuery,
  useGetAllCategoriesQuery,
  useLazyGetMinMaxDateQuery,
} from "../../features/services/trendingApps/trendingAppApiSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  setAppPrimaryData,
  setComprativeJsonModel,
  setComprativeOneData,
  setComprativeTwoData,
  setComprativeTwoJsonModel,
  setPrimaryJsonModel,
  setInitialApp,
  setRouteFlag,
} from "../../features/services/overView/overViewSlice";
import {
  getAppList,
  getAudienceAppList,
  getInitialAppList,
} from "../../features/services/search/searchAppSlice";
import {
  setPrimaryAppLineChartData,
  setComprativeAppLineChartOneData,
  setComprativeAppLineChartTwoData,
  setUserEngageMentPrimaryData,
  setUserEngageMentComprativeOneData,
  setUserEngageMentComprativeTwoData,
  setPrimaryAppEngagementRateData,
  setComparativeAppOneEngagementRateData,
  setComparativeAppTwoEngagementRateData,
} from "../../features/services/userActivity/userActivitySlice";
import { useLazyGetSearchedAppQuery } from "@/features/services/search/serachAppApiSlice";
import TrendingSkeleton from "../../containers/SkeletonLoader/TrendingSkeleton";
import { setError } from "../../features/services/errorSlice";
import { generateErrorMessage } from "../../Util/utils";
import {
  setAllCategories,
  setTrendingAppList,
  selectTrendingAppslist,
} from "@/features/services/trendingApps/trendingAppSlice";
import {
  setMaxMinDate,
  setDateRange,
  setAppDashBoardLabel,
} from "../../features/services/dateRange/dateRangeSlice";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import NoApp from "./NoApp";
import _ from "lodash";
import { numberFormatter } from "@/Util/utils";

const TrendingAppsDashboard = () => {
  const dispatch = useDispatch();
  const [selectedCategories, setSelectedCategories] = useState<string[]>([]),
    [isSelectAll, setIsSelectAll] = useState(false),
    [rankFilter, setRankFilter] = useState<string | null>("num_sessions"),
    [timeFilter, setTimeFilter] = useState<string | null>("all_time");
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [allCategoriesObj, setAllCategoriesObj] = useState([]);
  const [filteredCategories, setFilteredCategories] = useState([]);
  const [categoryList, setCategoryList] = useState([]);

  const navigate = useNavigate();
  const {
    data: categories,
    isError: categoriesErr,
    error: categoriesErrMessage,
  } = useGetAllCategoriesQuery("");
  const [
    fetchSearchOption,
    { data: appList, isError: searchErr, error: searchErrMsg },
  ] = useLazyGetSearchedAppQuery<any>();
  const [
    fetchTrendingAppsData,
    { isError: trendingAppErr, error: trendingAppErrMessage, isLoading },
  ] = useLazyGetTrendingAppsQuery();
  const [loading, setLoading] = useState(isLoading);
  const [fetchMinMaxDate, { data: startAndEndDateInEs }] =
    useLazyGetMinMaxDateQuery<any>();

  const getTrendingAppCategory = () => {
    if (selectedCategories.length) {
      return selectedCategories.length === categories.length
        ? ["all_categories"]
        : selectedCategories;
    }
    return ["all_categories"];
  };

  const [localTrendingApps, setLocalTendingAppList] = useState<any>(null);

  const trendingAppsData = useSelector(selectTrendingAppslist);

  const trendingApps = trendingAppsData?.trendingApps;
  const customInterval = trendingAppsData?.interval;
  const [interval, setInterval] = useState<any>(null);
  const [relatedApp, setRelatedApp] = useState([]);

  const [searchTerm, setSearchTerm] = useState("");
  const [searchTermCat, setSearchTermCat] = useState("");

  const timeFilterData = [
    { value: "week", label: "By Week" },
    { value: "month", label: "By Month" },
    { value: "all_time", label: "All Time" },
  ];
  const rankFilterData = [
    { value: "num_sessions", label: "Number of Sessions" },
    { value: "num_events", label: "Number of Events" },
  ];

  useEffect(() => {
    setLoading(true);
    const getMinMaxDate = async () => {
      const { data } = await fetchMinMaxDate("");
      dispatch(setMaxMinDate(data?.startAndEndDateInEs));
    };
    getMinMaxDate();

    fetchTrendingAppsData({
      trendingAppQueryParam: {
        appCategory: getTrendingAppCategory(),
        intervalType: timeFilter,
        offset: 10,
        rankBy: rankFilter,
        appName: searchTerm,
      },
    }).then((res: any) => {
      setLoading(false);
      if (res?.data !== undefined) {
        setLocalTendingAppList(res?.data);
      }
    });
  }, []);

  useEffect(() => {
    setLoading(true);
    fetchTrendingAppsData({
      trendingAppQueryParam: {
        appCategory: getTrendingAppCategory(),
        intervalType: timeFilter,
        offset: 10,
        rankBy: rankFilter,
        appName: searchTerm,
      },
    }).then((res: any) => {
      setLoading(false);
      if (res?.data !== undefined) {
        dispatch(setTrendingAppList(res?.data));
        setLocalTendingAppList(res?.data);
      }
    });
  }, [selectedCategories, isSelectAll, rankFilter, timeFilter]);

  useEffect(() => {
    if (
      startAndEndDateInEs !== undefined &&
      startAndEndDateInEs?.startAndEndDateInEs !== undefined
    ) {
      const { startDateInEs, endDateInEs } =
        startAndEndDateInEs?.startAndEndDateInEs;
      const dateRange = {
        dateLabel: "lastWeek",
        calFromDates: moment(endDateInEs)
          .subtract(6, "days")
          .format("YYYY-MM-DD"),
        calToDates: endDateInEs,
        fromDate: moment(endDateInEs).subtract(6, "days").format("YYYY-MM-DD"),
        toDate: endDateInEs,
      };
      const appDateLabel = `Last Week_(${moment(endDateInEs)
        .subtract(6, "days")
        .format("DD MMM YYYY")} - ${moment(endDateInEs).format(
        "DD MMM YYYY"
      )})`;
      dispatch(setAppDashBoardLabel(appDateLabel));
      dispatch(setDateRange(dateRange));
      if (timeFilter === "month") {
        const interval = {
          fromDate: moment(endDateInEs)
            .subtract(27, "days")
            .format("YYYY-MM-DD"),
          toDate: endDateInEs,
        };
        setInterval(interval);
      } else if (timeFilter === "week") {
        const interval = {
          fromDate: moment(endDateInEs)
            .subtract(6, "days")
            .format("YYYY-MM-DD"),
          toDate: endDateInEs,
        };
        setInterval(interval);
      } else if (timeFilter === "all_time") {
        const interval = {
          fromDate: startDateInEs,
          toDate: endDateInEs,
        };
        setInterval(interval);
      }
    }
  }, [startAndEndDateInEs, timeFilter]);

  useEffect(() => {
    if (categoriesErrMessage) {
      const message = generateErrorMessage(categoriesErrMessage);
      dispatch(setError(message));
    }
    if (trendingAppErrMessage) {
      const message = generateErrorMessage(trendingAppErrMessage);
      dispatch(setError(message));
    }
    if (searchErrMsg) {
      const message = generateErrorMessage(searchErrMsg);
      dispatch(setError(message));
    }
  }, [categoriesErr, trendingAppErr, searchErr]);
  useEffect(() => {
    dispatch(setRouteFlag(false));
    if (appList === undefined) {
      fetchSearchOption("").unwrap();
    }
    if (appList !== undefined) {
      if (
        localTrendingApps !== null &&
        trendingApps?.length > 0 &&
        appList !== undefined
      ) {
        const trendingAppsData = localTrendingApps?.trendingApps?.map(
          (item: any) => {
            return {
              value: item?.appName,
              label: item.appName,
              appicon: item?.iconUrl,
              id: item?.appId,
              categoryList: item?.appCategory,
            };
          }
        );
        dispatch(getAudienceAppList(appList));
        const appList1 = [...trendingAppsData, ...appList];
        const data = appList1?.filter(
          (value: any, index: any, self: any) =>
            index === self.findIndex((t: any) => t.id === value.id)
        );
        dispatch(getAppList(data));
        dispatch(getInitialAppList(data));
      }
    }
  }, [
    trendingApps,
    appList,
    categories,
    getAudienceAppList,
    filteredCategories,
  ]);

  const [serachDisplayError, setSerachDisplayError] = useState<any>(false);

  useEffect(() => {
    var regexp = new RegExp(/^[a-zA-Z0-9 ]*$/);
    if (regexp.test(searchTerm)) {
      setLoading(true);
      fetchTrendingAppsData({
        trendingAppQueryParam: {
          appCategory: getTrendingAppCategory(),
          intervalType: timeFilter,
          offset: 10,
          rankBy: rankFilter,
          appName: searchTerm,
        },
      }).then((res: any) => {
        if (res?.data !== undefined) {
          dispatch(setTrendingAppList(res?.data));
          setLocalTendingAppList(res?.data);
        }
        setLoading(false);
      });
    }
  }, [searchTerm, fetchTrendingAppsData]);

  useEffect(() => {
    let filterData =
      searchTermCat.length > 0
        ? allCategoriesObj.filter(
            (item: any) =>
              item?.name?.toLowerCase()?.includes(searchTermCat.toLowerCase())
          )
        : allCategoriesObj;
    setFilteredCategories(filterData);
  }, [searchTermCat]);

  useEffect(() => {
    if (filteredCategories?.length > 0) {
      setCategoryList(_.map(filteredCategories, "name"));
    }
  }, [filteredCategories]);

  useEffect(() => {
    if (categories?.length > 0) {
      const availableAllCategories = categories?.map(
        (item: any, index: any) => ({
          name: item,
          id: index + 1,
        })
      );
      setAllCategoriesObj(availableAllCategories);
      setFilteredCategories(availableAllCategories);
      setCategoryList(categories);
    }
  }, [categories]);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    var regexp = new RegExp(/^[a-zA-Z0-9 ]*$/);
    if (regexp.test(event.target.value)) {
      setSerachDisplayError(false);
      setSearchTerm(event.target.value);
    } else {
      setSerachDisplayError(true);
    }
  };

  const handleSearchChangeCat = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSearchTermCat(event.target.value);
  };

  const handleClick = (e: any) => {
    setSelectedCategories(e);
    if (categories?.length == e.length) {
      setIsSelectAll(true);
    } else {
      setIsSelectAll(false);
    }
  };
  const handleSelectAll = () => {
    setIsSelectAll(!isSelectAll);
    setSelectedCategories(categoryList);
    if (isSelectAll) {
      setSelectedCategories([]);
    }
  };

  const clearAll = () => {
    setSelectedCategories([]);
    setIsSelectAll(false);
    setSearchTermCat("");
  };

  return (
    <Grid className="trending-app-dashboard">
      <Grid.Col span={12}>
        <Card
          shadow="sm"
          radius="md"
          className="back-ground-image"
          sx={{
            "@media (max-width: 64em)": {
              backgroundSize: "cover !important",
            },
          }}
        >
          <Card.Section className="top-card"></Card.Section>
        </Card>
      </Grid.Col>
      {!isLoading ? (
        <>
          <Grid.Col span={12} className="mt-4">
            <Flex justify="space-between" align="flex-start" direction="row">
              <div>
                <Flex align={"center"}>
                  <Text className="text-2xl">Top Trending Apps</Text>
                  <Tooltip
                    label="Top apps identified by number of users"
                    withArrow
                    openDelay={500}
                    closeDelay={100}
                  >
                    <InformationCircleIcon className="h-6 w-6 ml-1 cursor-pointer" />
                  </Tooltip>
                </Flex>
                <Text fz="xs" className="text-secondary">
                  {moment(interval?.fromDate).format("DD MMM YYYY")}-
                  {moment(interval?.toDate).format("DD MMM YYYY")}
                </Text>
              </div>
              <Flex>
                <Flex direction={"column"}>
                  <Input
                    className=" mr-4"
                    icon={
                      <MagnifyingGlassIcon
                        height={20}
                        width={20}
                        color="#6E7B91"
                      />
                    }
                    placeholder="Search by app name "
                    value={searchTerm}
                    onChange={handleSearchChange}
                  />
                  {serachDisplayError && (
                    <Text className="error" fz="xs">
                      please enter a valid character
                    </Text>
                  )}
                </Flex>
                <Popover
                  width={300}
                  trapFocus
                  position="bottom-end"
                  withArrow
                  shadow="md"
                >
                  <Popover.Target>
                    <Button
                      size="xs"
                      fullWidth
                      variant="outline"
                      className="categories-button"
                    >
                      <Text fz="sm">
                        {selectedCategories.length > 0
                          ? selectedCategories.length + " selected"
                          : "All Categories"}
                      </Text>
                      <ChevronDownIcon className="h-4 w-4 ml-2" />
                    </Button>
                  </Popover.Target>
                  <Popover.Dropdown
                    className="categories-dropdown"
                    sx={(theme) => ({
                      background:
                        theme.colorScheme === "dark"
                          ? theme.colors.dark[7]
                          : theme.white,
                    })}
                  >
                    <Input
                      className="mb-3 mr-4"
                      icon={
                        <MagnifyingGlassIcon
                          height={20}
                          width={20}
                          color="#6E7B91"
                        />
                      }
                      placeholder="Search "
                      value={searchTermCat}
                      onChange={handleSearchChangeCat}
                    />
                    {searchTermCat.length >= 0 &&
                      filteredCategories?.length > 0 && (
                        <Group
                          sx={{
                            alignItems: "start",
                            justifyContent: "space-between",
                          }}
                        >
                          <Checkbox
                            value={"all_categories"}
                            label={"Select All"}
                            className="mb-4"
                            onChange={() => handleSelectAll()}
                            checked={isSelectAll}
                          />
                          <Text
                            style={{
                              fontSize: "14px",
                              textDecoration: "underline",
                            }}
                          >
                            <a
                              style={{ color: "#e20074", cursor: "pointer" }}
                              onClick={clearAll}
                            >
                              Clear all
                            </a>
                          </Text>
                        </Group>
                      )}
                    {searchTermCat.length >= 0 &&
                      filteredCategories?.length > 0 && (
                        <Checkbox.Group
                          value={selectedCategories}
                          onChange={(e) => handleClick(e)}
                        >
                          <SimpleGrid>
                            {filteredCategories?.map((category: any) => {
                              return (
                                <Checkbox
                                  value={category.name}
                                  label={category.name}
                                  key={category.id}
                                />
                              );
                            })}
                          </SimpleGrid>
                        </Checkbox.Group>
                      )}
                    {searchTermCat.length > 0 &&
                      filteredCategories?.length === 0 && <>No Match !</>}
                  </Popover.Dropdown>
                </Popover>
                <Select
                  rightSection={<ChevronDownIcon className="h-4 w-4 ml-2" />}
                  rightSectionWidth={30}
                  styles={{ rightSection: { pointerEvents: "none" } }}
                  data={timeFilterData}
                  defaultValue="all_time"
                  className="w-40"
                  value={timeFilter}
                  onChange={setTimeFilter}
                />
              </Flex>
            </Flex>
          </Grid.Col>
          <Grid.Col span={12}>
            <Flex justify="space-between" align="center" direction="row">
              <Text fz="sm">App</Text>
              {!loading && trendingApps?.length > 0 && (
                <Flex align="center" className="rank-dropdown">
                  <Text className="mr-4 text-secondary" fz="sm">
                    Rank view by :
                  </Text>
                  <Select
                    rightSection={<ChevronDownIcon className="h-4 w-4 ml-2" />}
                    rightSectionWidth={60}
                    styles={{ rightSection: { pointerEvents: "none" } }}
                    data={rankFilterData}
                    value={rankFilter}
                    onChange={setRankFilter}
                  />
                  <Tooltip
                    label={
                      rankFilter === "num_sessions"
                        ? "Number of sessions reflects how often users interact with the app, counting each instance of engagement as one session in an hour"
                        : "Number of events represent the total number of times an application is used."
                    }
                    multiline
                    width={300}
                  >
                    <InformationCircleIcon className="h-6 w-6 -ml-4 z-1 cursor-pointer" />
                  </Tooltip>
                </Flex>
              )}
            </Flex>
            {!loading && trendingApps?.length === 0 && <NoApp />}
            {!loading ? (
              <div>
                {trendingApps?.map((app: any) => {
                  return (
                    <Card
                      shadow="sm"
                      radius="md"
                      withBorder
                      className="mb-2 p-3 cursor-pointer"
                      onClick={() => {
                        dispatch(
                          setInitialApp({
                            appId: app.appId,
                            appName: app.appName,
                          })
                        );
                        navigate("/appProfile/" + app?.appName, {
                          state: {
                            appId: app?.appId,
                            appName: app?.appName,
                          },
                        });
                      }}
                      key={app.appId}
                    >
                      <Card.Section>
                        <Grid>
                          <Grid.Col span={9} style={{ display: "flex" }}>
                            <Image
                              width={80}
                              alt="App Icon"
                              src={app.iconUrl ? app.iconUrl : DefaultAppImage}
                              className="mr-4"
                            />
                            <Text className="text-xl ml-8 py-5">
                              {app.appName} <br />
                              {app?.appCategory?.map((category: any) => {
                                return (
                                  <Text
                                    fz="xs"
                                    className="text-secondary mr-1 category"
                                    key={`${app.appId}-${category}`}
                                  >
                                    {category}
                                  </Text>
                                );
                              })}
                            </Text>
                          </Grid.Col>
                          <Grid.Col
                            span={"auto"}
                            style={{
                              display: "flex",
                              justifyContent: "flex-start",
                              alignItems: "center",
                            }}
                          >
                            {app.rankChange !== 0 && (
                              <Tooltip
                                label={
                                  app.rankChangeDifferenceCount !== "0"
                                    ? app.rankChangeDifferenceCount
                                    : ""
                                }
                              >
                                <Text
                                  fz="sm"
                                  style={{
                                    paddingLeft: "8px",
                                    paddingRight: "8px",
                                    cursor: "pointer",
                                    color: `${
                                      app.rankChange > 0 ? "#38A169" : "#F56565"
                                    }`,
                                  }}
                                >
                                  {app.rankChange > 0 ? "+" : "-"}{" "}
                                  {app.rankChange.toString().replace(/-/g, "")}
                                </Text>
                              </Tooltip>
                            )}
                            {app.rankChange === 0 && (
                              <Text
                                fz="sm"
                                style={{
                                  paddingLeft: "8px",
                                  paddingRight: "8px",
                                  visibility: "hidden",
                                }}
                              >
                                +0
                              </Text>
                            )}
                            {app.rankChange < 0 && (
                              <ArrowDownIcon
                                height={20}
                                width={20}
                                color="#F56565"
                                className="mr-4"
                              />
                            )}
                            {app.rankChange > 0 && (
                              <ArrowUpIcon
                                height={20}
                                width={20}
                                color="#38A169"
                                className="mr-4"
                              />
                            )}
                            {app.rankChange === 0 && (
                              <span
                                style={{
                                  visibility: "hidden",
                                }}
                              >
                                <ArrowDownIcon
                                  height={20}
                                  width={20}
                                  color="#F56565"
                                  className="mr-4"
                                />
                              </span>
                            )}
                            <Text className="text-4xl mr-2  text-secondary">
                              {app.rank}
                            </Text>
                          </Grid.Col>
                          <Grid.Col
                            span={"auto"}
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <Text
                              className="mr-4 mt-2"
                              style={{ color: "#6E7B91", fontSize: "14px" }}
                            >
                              ({numberFormatter(app.count)} {` `}
                              {rankFilter === "num_events" ? "Eve." : "Ses."})
                            </Text>
                          </Grid.Col>
                        </Grid>
                      </Card.Section>
                    </Card>
                  );
                })}
              </div>
            ) : (
              <div>
                {[1, 2, 3, 4, 5].map((item: any) => {
                  return (
                    <Card withBorder className="my-3 p-1" key={item}>
                      <Grid justify={"space-between"}>
                        <Grid.Col span={2}>
                          <Skeleton height={70} width="20%" my={0} />
                        </Grid.Col>
                        <Grid.Col span={9}>
                          <Skeleton height={70} width="100%" my={0} />
                        </Grid.Col>
                        <Grid.Col span={1} style={{ position: "relative" }}>
                          <Skeleton
                            height={70}
                            width="20%"
                            my={0}
                            style={{ position: "absolute", right: 20 }}
                          />
                        </Grid.Col>
                      </Grid>
                    </Card>
                  );
                })}
              </div>
            )}
          </Grid.Col>
        </>
      ) : (
        <Grid className="w-full my-40">
          <Grid.Col span={12}>
            <TrendingSkeleton />
          </Grid.Col>
        </Grid>
      )}
    </Grid>
  );
};

export default Hoc(TrendingAppsDashboard);
