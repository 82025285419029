import axios from "axios";
import CryptoJS from "crypto-js";
import {emetriqLoginauthenticateUser, userPool} from "./emetriqLogin";
import {
  AuthenticationDetails,
  CognitoUser,
} from "amazon-cognito-identity-js";
import { notifications } from '@mantine/notifications';


const BASE_URL: string = window.location.href.includes("test")
  ? "https://gateway.test.ma2.telekom.com/insights-service"
  : window.location.href.includes("demo")
  ? "https://gateway.demo.ma2.telekom.com/insights-service"
  : "https://gateway.dev.ma2.telekom.com/insights-service";

const login = (
  userName: string,
  password: string,
  callback: any,
  errCallback: any,
) => {
  const cryticPassword = CryptoJS.SHA256(password).toString();
  axios
    .post(
      `${BASE_URL}/keycloak/authenticateAndAuthorizeUser`,
      {
        username: userName,
        credentials: [
          {
            value: cryticPassword,
          },
        ],
      },
      {
        headers: {
          "X-Frame-Options": "SAMEORIGIN",
        },
      },
    )
    .then(async (response) => {
      response.data.realmRoles.includes("MA2-ADMIN") === true ?
      sessionStorage.setItem("admin", "true") :  sessionStorage.setItem("admin", "false");
      if (response.data.accessToken && !response.data.error) {
          try {
            let authenticatedUser:any;
              if(response.data.realmRoles.includes('TV-INSIGHTS') === true) {
                const user = new CognitoUser({ Username: "dt_india", Pool: userPool });
              const authenticationDetails = new AuthenticationDetails({
                Username: "dt_india",
                Password: "Dev@12345678",
              });
              authenticatedUser = await emetriqLoginauthenticateUser(
                user,
                authenticationDetails
              );
              sessionStorage.setItem("emetriqLogin", JSON.stringify(authenticatedUser?.idToken?.jwtToken));
            }
             
            callback(response);
            return authenticatedUser;
          } catch(e:any){
            notifications.show({
              id: 'emetriq-login',
              withCloseButton: true,
              autoClose: 3000,
              title: "Tv Reach Login is not working",
              message: e.message,
              color: 'red',
              className: 'my-notification-class',
              style: { backgroundColor: 'white' },
              sx: { backgroundColor: 'red' },
              loading: false,
            });
             callback(response);
             throw e;
          }
        
      }
    })
    .catch((err) => {
      errCallback(err);
    });
};

export default login;
