import React from "react";
import { Text, Flex, Image } from "@mantine/core";
import Frame from "../../assets/images/Frame.png";

interface ItemProps extends React.ComponentPropsWithoutRef<"div"> {
  appicon: string;
  label: string;
  value: string;
  categoryList?: string[];
}

const SelectItem = React.forwardRef<HTMLDivElement, ItemProps>(
  (
    { value, label, appicon, style, categoryList, ...others }: ItemProps,
    ref
  ) => {
    return (
      <div {...others} ref={ref} className={"select-search"} key={value}>
        <Flex justify={"space-between"}>
          <Flex direction={"row"}>
            {appicon !== undefined && (
              <Image
                src={appicon}
                style={{ width: "20px", height: "20px" }}
                role={"icons"}
                placeholder={
                  <Image
                    src={Frame}
                    style={{ width: "20px", height: "20px" }}
                  />
                }
                withPlaceholder
              />
            )}
            <div className="ml-2">
              <Text size="sm">{label}</Text>
            </div>
          </Flex>
          <div>
            {window?.location?.href?.includes("insights") == true && (
              <Text
                fz="xs"
                title={JSON.stringify(categoryList?.join(", "))
                  ?.replace(/[\[\]']+/g, "")
                  ?.replace(/^"|"$/g, "")}
              >
                {JSON.stringify(categoryList?.join(", "))
                  ?.replace(/[\[\]']+/g, "")
                  ?.replace(/^"|"$/g, "")
                  .slice(0, 10) +
                  (JSON.stringify(categoryList?.join(", "))
                    ?.replace(/[\[\]']+/g, "")
                    ?.replace(/^"|"$/g, "")?.length > 10
                    ? "..."
                    : "")}
              </Text>
            )}
          </div>
        </Flex>
      </div>
    );
  }
);

export default SelectItem;
