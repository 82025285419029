import { DatePicker } from "@mantine/dates";
import { Popover } from "@mantine/core";
import React from "react";
import "./MaDateRange.scss";
import moment from "moment";

const MaDateRange = (props: any) => {
  const [value, setValue] = React.useState<[Date | null, Date | null]>([
    null,
    null,
  ]);

  var yesterday: any = moment("20-11-2023", "DD-MM-YYYY").toDate();
  var startDate: any = moment("14-11-2023", "DD-MM-YYYY").toDate();
  var endDate: any = moment("20-11-2023", "DD-MM-YYYY").toDate();
  const newValue: any =
    props.defaultValue === "Yesterday"
      ? [yesterday, yesterday]
      : [startDate, endDate];

  return (
    <div className="ma-date-range">
      <Popover
        width={"auto"}
        position="bottom"
        withArrow
        shadow="md"
        opened={props.opened}
        onChange={props.setOpened}
        arrowSize={15}
        closeOnClickOutside
      >
        {props.children && <Popover.Target>{props.children}</Popover.Target>}
        <Popover.Dropdown>
          <DatePicker
            type="range"
            numberOfColumns={props.numberOfColumns || 2}
            defaultValue={newValue}
            minDate={props.defaultValue === "Yesterday" ? yesterday : startDate}
            maxDate={endDate}
          />
        </Popover.Dropdown>
      </Popover>
    </div>
  );
};

export default MaDateRange;
