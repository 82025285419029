import { apiSlice } from "../api/apiSlice";
import { gql } from "graphql-request";

export interface TvApiAccessToken {
   tvApiAccessToken: any
}


const getActiveHouseHoldsCount: string = gql`
  query getActiveHouseHoldsCount($tvApiAccessToken: TvApiAccessToken) {
    getActiveHouseHoldsCount(tvApiAccessToken: $tvApiAccessToken){
        activeHouseholdsCount
    }
}`;



const getCustomerSpotDetails = gql`
  query getCustomerSpotDetails($tvApiAccessToken: TvApiAccessToken){
    getCustomerSpotDetails(tvApiAccessToken: $tvApiAccessToken){
      customer
      spots {
          spot
          spotIds
        }
      }
    }
  `;
const getEmetriqNetReachReport = gql`
  query getNetReachReport($tvApiAccessToken: TvApiAccessToken){
    getEmetriqNetReachReport(tvApiAccessToken: $tvApiAccessToken){
      date
      productName
      netReach
      kk0
      kk14
      kk57
      kk810
      kk11plus
      mediaCost
      customer
  }
}   
`

const getEmetriqMediaCostForSpots = gql` 
  query getEmetriqMediaCostForSpots($tvApiAccessToken: TvApiAccessToken)
    {
      getEmetriqMediaCostForSpots(tvApiAccessToken: $tvApiAccessToken){
      date
      customer
      spotId
      spot
      mediaCost
  }
}` 

const getEmetriqGrpOtsReport = gql`
  query getEmetriqGrpOtsReport($tvApiAccessToken: TvApiAccessToken) {
    getEmetriqGrpOtsReport(tvApiAccessToken: $tvApiAccessToken){
      date
      brandName
      spot
      spotId
      countHousehold
      countSpots
      mgrp
      ots  
  }
}`   

export const tvReachAppSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getActiveHouseHoldsCount: builder.query<string, any>({
      query: (variables: any): any => ({
        document: getActiveHouseHoldsCount,
        variables,
      }),
      transformResponse: (response: any): any => {
        return response;
      },
    }),
    getCustomerSpotDetails: builder.query<any, any>({
      query: (variables: any): any => ({
        document: getCustomerSpotDetails,
        variables,
      }),
      transformResponse: (response: any): any => {
        return response;
      },
    }),
    getEmetriqNetReachReport: builder.query<any,any>({
        query: (variables: any): any => ({
          document: getEmetriqNetReachReport,
          variables,
        }),
        transformResponse: (response: any): any => {
           return response?.getEmetriqNetReachReport?.map((item:any) => {
            return {
              date: item.date,
              productName: item.productName,
              netReach: parseInt(item.netReach),
              kk0: parseInt(item.kk0),
              kk14: parseInt(item.kk14),
              kk57: parseInt(item.kk57),
              kk810: parseInt(item.kk810),
              kk11plus: parseInt(item.kk11plus),
              mediaCost:  parseFloat(item.mediaCost),
              customer: item.customer,
              absoluteNetReach: parseInt(item.netReach),
            }
          });
        },
    }),
    getEmetriqMediaCostForSpots: builder.query<any, any>({
      query: (variables: any): any => ({
        document: getEmetriqMediaCostForSpots,
        variables,
      }),
      transformResponse: (response: any): any => {
        return response;
      },
    }),
    getEmetriqGrpOtsReport: builder.query<any, any>({
       query: (variables: any): any => ({
        document: getEmetriqGrpOtsReport,
        variables,
      }),
      transformResponse: (response: any): any => {
        return response?.getEmetriqGrpOtsReport;
      },
    })
  }),
});
export const { 
  useLazyGetActiveHouseHoldsCountQuery,
  useLazyGetCustomerSpotDetailsQuery,
  useLazyGetEmetriqNetReachReportQuery,
  useLazyGetEmetriqMediaCostForSpotsQuery,
  useLazyGetEmetriqGrpOtsReportQuery
} =
  tvReachAppSlice;
