import React, { useEffect, useRef } from "react";
import ReactECharts from "echarts-for-react";
import { useSelector } from "react-redux";
import { selectLightTheme } from "@/features/services/themes/themeSlice";

interface BarChartProps {
  height?: string;
  data: { label: string; value: number }[];
  chartName: string;
  yPrefix?: string;
  colors?: any;
  cornerRadius?: number;
  minGridDistance?: number;
  xLabelRotation?: number;
  format?: string;
}

const BarChart: React.FC<BarChartProps> = ({
  height = "500px",
  data,
  chartName,
  yPrefix = "",
  colors,
  xLabelRotation = 0,
  format = "#,###.##",
}) => {
  const themeProvider = useSelector(selectLightTheme);
  const chartRef = useRef<any>(null);

  const getOption: any = (): any => {
    const labels = data.map((item) => item.label);
    const values = data.map((item) => item.value);

    const echartsData = values.map((value, index) => ({
      value,
      itemStyle: { color: colors?.[index] || "#FEFF61" },
    }));

    const series: any = [
      {
        name: chartName,
        type: "bar",
        data: echartsData,
        barWidth: `40px`,
        tooltip: {
          formatter: "{b}: {c}",
        },
      },
    ];

    return {
      grid: {
        containLabel: true,
        x: 30,
        y: 30,
        x2: 30,
        y2: 50,
      },
      title: {
        text: chartName,
        left: "center",
        textStyle: {
          color: themeProvider === "light" ? "#00022d" : "#ffffff",
        },
      },
      tooltip: {
        trigger: "item",
      },
      xAxis: {
        type: "category",
        data: labels,
        axisLabel: {
          rotate: xLabelRotation,
        },
        axisTick: { show: false },
        axisLine: { show: true },
      },
      yAxis: {
        type: "value",
        name: yPrefix,
        axisLabel: {
          formatter: (value: any) => `${yPrefix}${value}`,
        },
        axisLine: { show: true },
        axisTick: { show: false },
      },
      series,
    };
  };

  useEffect(() => {
    if (chartRef.current) {
      chartRef.current.getEchartsInstance().resize();
    }
  }, [data]);

  return (
    <div style={{ height }}>
      <ReactECharts
        ref={chartRef}
        option={getOption()}
        style={{ height: "100%" }}
      />
    </div>
  );
};

export default BarChart;
