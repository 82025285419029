import { createSlice } from "@reduxjs/toolkit";

interface InitialState {
  appList: string[] | null;
  initialAppList: string[] | null;
  comprativeAppOneOptions: string[] | null;
  comprativeAppTwoOptions: string[] | null;
  audienceAppList: any | null;
  suggestionArray: any | null;
  suggestedCategoryLabel: string | null;
}

const initialState: InitialState = {
  audienceAppList: null,
  appList: null,
  initialAppList: null,
  comprativeAppOneOptions: null,
  comprativeAppTwoOptions: null,
  suggestionArray: null,
  suggestedCategoryLabel: ""
};

const searchAppSlice = createSlice({
  name: "serach",
  initialState: { ...initialState },
  reducers: {
    getInitialAppList: (state, action) => {
      const initialAppList = action?.payload;
      state.initialAppList = initialAppList;
    },
    getAppList: (state, action) => {
      const appList =
        action?.payload?.appList === undefined
          ? action.payload
          : action?.payload?.appList;
      state.appList = appList;
    },
    getComporarativeAppOneList: (state, action) => {
      const appList =
        action?.payload?.appList === undefined
          ? action.payload
          : action?.payload?.appList;
      state.comprativeAppOneOptions = appList;
    },
    getComporarativeAppTwoList: (state, action) => {
      const appList =  action?.payload?.appList === undefined
          ? action.payload
          : action?.payload?.appList;
      state.comprativeAppTwoOptions = appList;
    },
    getAudienceAppList: (state, action) => {
      const audienceAppList = action.payload;
      state.audienceAppList = audienceAppList;
    },
    setSuggestionArray: (state, action) => {
      const suggestionArray = action.payload;
      state.suggestionArray = suggestionArray;
    },
    setSuggestedCategory: (state, action) => {
      const suggestedCategoryLabel = action.payload;
      state.suggestedCategoryLabel = suggestedCategoryLabel;
    }
  },
});

export const {
  getAppList,
  getInitialAppList,
  getComporarativeAppOneList,
  getComporarativeAppTwoList,
  getAudienceAppList,
  setSuggestionArray,
  setSuggestedCategory
} = searchAppSlice.actions;

export default searchAppSlice.reducer;

export const selectAppList = (state: any) => state.search.appList;

export const selectInitialAppList = (state: any) => state.search.initialAppList;

export const selectedComprativeAppOneOptions = (state: any) => {
  return state.search.comprativeAppOneOptions;
};

export const selecedComprativeAppTwoOptions = (state: any) =>
  state.search.comprativeAppTwoOptions;

export const selectAudienceAppList = (state: any) =>
  state.search.audienceAppList;

export const selectSuggestionAppList = (state: any) =>
  state.search.suggestionArray;

export const selectSuggestedLabel = (state:any) => state.search.suggestedCategoryLabel;
