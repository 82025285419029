import axios from "axios";

const BASE_URL: string = window.location.href.includes("test")
  ? "https://gateway.test.ma2.telekom.com/insights-service"
  : window.location.href.includes("demo")
  ? "https://gateway.demo.ma2.telekom.com/insights-service"
  : "https://gateway.dev.ma2.telekom.com/insights-service";

const getUsers = (usersData: any, errData: any) => {
  axios
    .get(
      `${BASE_URL}/keycloakAdmin/fetchAllUsers`,

      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("accesstoken")}`,
          "X-Frame-Options": "SAMEORIGIN",
        },
      },
    )
    .then((response) => {
      let ArrangedDataOfUser = [];
      for (let val of response.data) {
        let obj:any = {
          username: "",
          insight: false,
          audience: false,
          admin: false,
          tvInsight: false,
          dataSource: [],
        };
        obj.username = val.username;
        obj.insight = val.realmRoles.includes("INSIGHTS");
        obj.audience = val.realmRoles.includes("AUDIENCE");
        obj.tvInsight = val.realmRoles.includes("TV-INSIGHTS")
        obj.admin = val.realmRoles.includes("MA2-ADMIN");
        obj.dataSource = val.realmRoles;
        ArrangedDataOfUser.push(obj);
      }
      usersData(ArrangedDataOfUser);
    })
    .catch((err) => {
      console.log(err);
      errData(err);
    });
};

export default getUsers;
