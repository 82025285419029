import React, { useState, useEffect } from "react";
import { Select } from "@mantine/core";

interface DropdownProps {
  data: string[];
  value: string;
  updatedList: any;
  disabled: boolean;
}

const SingleSelectDropDown: React.FC<DropdownProps> = ({
  data,
  value,
  updatedList,
  disabled,
}) => {
  const [selectedValue, setSelectedValue] = useState<string | null>("");

  useEffect(() => {
    setSelectedValue(value);
  }, [value]);

  useEffect(() => {}), [data, value, updatedList];
  const onChangeHanlder = (val: string | null) => {
    setSelectedValue(val);
    updatedList(val);
  };

  return (
    <>
      <Select
        data={data}
        placeholder="Select a categories"
        value={selectedValue}
        searchable
        nothingFound="No Category"
        onChange={(selVal) => onChangeHanlder(selVal)}
        disabled={disabled}
      />
    </>
  );
};

export default SingleSelectDropDown;
