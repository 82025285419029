import { useState, useEffect, useRef } from "react";
import Hoc from "@/Hoc/Hoc";
import {
  Grid,
  Text,
  Box,
  Flex,
  Divider,
  Tooltip,
  Card,
  Title,
  TextInput,
  Checkbox,
  Group,
  Select,
  Image,
} from "@mantine/core";
import "./AppBlacklistDashboard.scss";
import {
  MagnifyingGlassIcon,
  ArrowsUpDownIcon,
  ChevronDownIcon,
} from "@heroicons/react/24/outline";
import {
  ChevronDoubleRightIcon,
  ChevronDoubleLeftIcon,
  NoSymbolIcon,
} from "@heroicons/react/24/solid";
import { useGetBlackListDataQuery } from "../../features/services/blacklisting/blackListingApiSlice";
import { useGetAllCategoriesQuery } from "../../features/services/trendingApps/trendingAppApiSlice";
import Frame from "../../assets/images/Frame.png";

const AppBlacklistingDashboard = () => {
  const { data: appList } = useGetBlackListDataQuery("");
  const [appData, setAppData] = useState(appList?.getApplicationsList);
  const {
    data: categories,
    isError: categoriesErr,
    error: categoriesErrMessage,
  } = useGetAllCategoriesQuery("");
  const [allCat, setAllCat] = useState(categories);

  useEffect(() => {
    setAppData(appList?.getApplicationsList);
  });
  const [isSelectAllApps, setIsSelectAllApps] = useState(false);
  const [isSelectAllBlacklistedApps, setIsSelectAllBlacklistedApps] =
    useState(false);

  const [selectedApps, setSelectedApps] = useState<string[]>([]);
  const [selectedBlacklistedApps, setSelectedBlacklistedApps] = useState<
    string[]
  >([]);

  const handleSelectAllApps = () => {
    const apps: any = appData?.allowedApps?.map(
      (element: any) => element.appName
    );
    setIsSelectAllApps(!isSelectAllApps);
    setSelectedApps(apps);
    if (isSelectAllApps) {
      setSelectedApps([]);
    }
  };
  const handleAppClick = (e: any) => {
    setSelectedApps(e);
    if (appData.allowedApps?.length === e?.length) {
      setIsSelectAllApps(true);
    } else {
      setIsSelectAllApps(false);
    }
  };

  const handleSelectAllBlacklistedApps = () => {
    const apps: any = appData?.blacklistedApps?.map(
      (element: any) => element.appName
    );
    setIsSelectAllBlacklistedApps(!isSelectAllBlacklistedApps);
    setSelectedBlacklistedApps(apps);
    if (isSelectAllBlacklistedApps) {
      setSelectedBlacklistedApps([]);
    }
  };
  const handleBlacklistedAppClick = (e: any) => {
    setSelectedBlacklistedApps(e);
    if (appData?.blacklistedApps?.length === e?.length) {
      setIsSelectAllBlacklistedApps(true);
    } else {
      setIsSelectAllBlacklistedApps(false);
    }
  };
  // const [fetchBlacklistedData] = useLazyGetBlackListDataQuery();

  const moveToBlackList = () => {
    // fetchBlacklistedData();
  };
  return (
    <>
      <Grid className="app-blacklisting-dashboard">
        <Grid.Col span={12}>
          <Title order={4}>App Blacklisting</Title>
        </Grid.Col>
        <Grid.Col span={6}>
          <Card shadow="sm" padding="lg" radius="md" withBorder>
            <Flex justify={"space-between"}>
              <Title order={5}>
                <Flex align={"center"}>
                  <Image width={20} height={20} src={Frame} alt={"frame"} />
                  <span className="ml-2">All Apps</span>
                </Flex>
              </Title>
              <span className="app-count">{appData?.allowedApps?.length}</span>
            </Flex>
            <Divider my="md" />
            <TextInput
              placeholder="Search by app name"
              icon={
                <MagnifyingGlassIcon className="h-5 w-5 cursor-pointer search-icon" />
              }
            ></TextInput>
            {selectedApps.length > 0 && (
              <Flex justify={"center"} className="mt-4">
                <Text>{selectedApps?.length} selected</Text>
                <Text
                  className="cursor-pointer ml-4 text-secondary underline"
                  onClick={() => {
                    setSelectedApps([]);
                    setIsSelectAllApps(false);
                  }}
                >
                  Clear All
                </Text>
              </Flex>
            )}
            <Flex className="mt-6" justify={"space-between"} align={"center"}>
              <div className="mt-2">
                <Checkbox
                  label={"App Name"}
                  className="inline-block"
                  onChange={() => handleSelectAllApps()}
                  checked={isSelectAllApps}
                />
                <ArrowsUpDownIcon className="h-4 w-4 ml-2 mb-3 cursor-pointer inline-block" />
              </div>
              <div className="category-dropdown">
                <Select
                  rightSection={<ChevronDownIcon className="h-4 w-4 ml-2" />}
                  rightSectionWidth={30}
                  styles={{ rightSection: { pointerEvents: "none" } }}
                  data={categories}
                  // value="All Categories"
                  placeholder="All Categories"
                  dropdownPosition="bottom"
                />
              </div>
            </Flex>
            <Divider my="xs" />
            <Checkbox.Group
              value={selectedApps}
              onChange={(e) => handleAppClick(e)}
              className="app-list"
            >
              {appData?.allowedApps?.map((element: any) => {
                return (
                  <Flex
                    justify={"space-between"}
                    align={"center"}
                    className="app-item"
                  >
                    <Checkbox
                      value={element?.appName}
                      label={element?.appName}
                    />
                    <Text fz="sm" className="category text-secondary">
                      {element?.categories.map((val: string, index: number) => {
                        const isLast = index === element?.categories.length - 1;
                        return isLast ? val : `${val}, `;
                      })}
                    </Text>
                  </Flex>
                );
              })}
            </Checkbox.Group>
          </Card>
        </Grid.Col>
        <Grid.Col span={1} className="text-center move-icons">
          <ChevronDoubleRightIcon
            className={selectedApps.length ? "active" : "disabled"}
            onClick={moveToBlackList}
          />
          <ChevronDoubleLeftIcon
            className={selectedBlacklistedApps.length ? "active" : "disabled"}
          />
        </Grid.Col>
        <Grid.Col span={5}>
          <Card shadow="sm" padding="lg" radius="md" withBorder>
            <Flex justify={"space-between"}>
              <Title order={5}>
                <Flex align={"center"}>
                  <NoSymbolIcon className="h-6 w-6 cursor-pointer inline-block no-symbol" />
                  <span className="ml-2">Blacklisted Apps </span>
                </Flex>
              </Title>
              <span className="blacklisted-app-count">
                {appData?.blacklistedApps?.length}
              </span>
            </Flex>
            <Divider my="md" />
            <TextInput
              placeholder="Search by app name"
              icon={
                <MagnifyingGlassIcon className="h-5 w-5 cursor-pointer search-icon" />
              }
            ></TextInput>
            {selectedBlacklistedApps.length > 0 && (
              <Flex justify={"center"} className="mt-4">
                <Text>{selectedBlacklistedApps?.length} selected</Text>
                <Text
                  className="cursor-pointer ml-4 text-secondary underline"
                  onClick={() => {
                    setSelectedBlacklistedApps([]);
                    setIsSelectAllBlacklistedApps(false);
                  }}
                >
                  Clear All
                </Text>
              </Flex>
            )}
            <Flex className="mt-6" justify={"space-between"} align={"center"}>
              <div className="mt-2">
                <Checkbox
                  label={"App Name"}
                  className="inline-block	"
                  onChange={() => handleSelectAllBlacklistedApps()}
                  checked={isSelectAllBlacklistedApps}
                />
                <ArrowsUpDownIcon className="h-4 w-4 ml-2 mb-3 cursor-pointer inline-block	" />
              </div>
              <div className="category-dropdown">
                <Select
                  rightSection={<ChevronDownIcon className="h-4 w-4 ml-2" />}
                  rightSectionWidth={30}
                  styles={{ rightSection: { pointerEvents: "none" } }}
                  data={categories}
                  // value="All Categories"
                  placeholder="All Categories"
                  dropdownPosition="bottom"
                />
              </div>
            </Flex>
            <Divider my="xs" />
            <Checkbox.Group
              value={selectedBlacklistedApps}
              onChange={handleBlacklistedAppClick}
              className="app-list"
            >
              {appData?.blacklistedApps?.map((element: any) => {
                return (
                  <Flex
                    justify={"space-between"}
                    align={"center"}
                    className="app-item"
                  >
                    <Checkbox
                      value={element?.appName}
                      label={element?.appName}
                    />
                    <Text fz="sm" className="category text-secondary">
                      {element?.categories.map((val: string, index: number) => {
                        const isLast = index === element?.categories.length - 1;
                        return isLast ? val : `${val}, `;
                      })}
                    </Text>
                  </Flex>
                );
              })}
            </Checkbox.Group>
          </Card>
        </Grid.Col>
      </Grid>
    </>
  );
};

export default Hoc(AppBlacklistingDashboard);
