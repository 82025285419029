import { environment } from "./enviorment";
import {
 AuthenticationDetails,
  CognitoUser,
  CognitoUserPool,
} from "amazon-cognito-identity-js";

export const POOL_DATA:any = {
  UserPoolId: environment.userPoolId,
  ClientId: environment.clientId,
};

export const userPool:any = new CognitoUserPool(POOL_DATA);

export const emetriqLoginauthenticateUser = (user: any, authenticationDetails: any) => {
    return new Promise((resolve:any, reject:any) => {
      user.authenticateUser(authenticationDetails, {
        onSuccess: (result: any) => {
          resolve(result);
        },
        onFailure: (err: any) => {
          reject(err);
        },
      });
    });
};