// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  stage: 'dev',
  hmr: false,
  region: 'eu-west-1',
  userPoolId: 'eu-west-1_4M0tSe8pg',
  clientId: '1u059v7nrn1co6827v2nba4b3t',
  apiBasePath: 'https://tv.api.dev.insights.emetriq.de',
  // apiBasePath: 'http://localhost:8900',
  trackingBasePath: 'https://tracking.insights.emetriq.de/tracking/insights',
  integrationBasePath:
    'https://0leky6tdjc.execute-api.eu-west-1.amazonaws.com/dev',
  cognitoPolicy: {
    MinimumLength: 8,
    RequireNumbers: false,
    RequireSymbols: false,
    RequireUppercase: false,
    RequireLowercase: false,
  },
  enableServiceWorker: false,
  animation: true,
  version: '_v0_',
  report: {
    warnNoDataAfterHours: 3,
  },
  asyncCharts: { pollingInterval: 5000, maxPollingTime: 1800000 },
  useAuthServiceMock: false,
  useLocalApi: false,
};
