import { useState, useEffect, useRef } from "react";
import Hoc from "@/Hoc/Hoc";
import { Grid, Text, Box, Flex, Divider, Tooltip, Card } from "@mantine/core";
import Frame from "@/assets/images/Frame.png";
import moment from "moment";
import { InformationCircleIcon } from "@heroicons/react/24/outline";
import {
  useLazyGetActiveHouseHoldsCountQuery,
  useLazyGetCustomerSpotDetailsQuery,
  useLazyGetEmetriqNetReachReportQuery,
  useLazyGetEmetriqMediaCostForSpotsQuery,
  useLazyGetEmetriqGrpOtsReportQuery,
} from "@/features/services/tvReach/tvReachApiSlice";
import generatePDF from "react-to-pdf";
import {
  setInitialAppArray,
  selectedInitialAppArray,
  selectedSportArray,
  selectImageArr,
  selectspotIdArr,
} from "@/features/services/tvReach/tvReachSlice";
import { useSelector, useDispatch } from "react-redux";
import SelectItem from "@/containers/SelectItem/SelectItems";
import "./TVDashboard.scss";
import { notifications } from "@mantine/notifications";
import { setRouteFlag } from "@/features/services/overView/overViewSlice";
import { commaNumber } from "./commaNumber";
import AreaChart from "./AreaChart/AreaCharts";
import LineBarChart from "./LineBarChart/LineBarChart";
import BarChart from "./BarChart/BarChart";
import Filter from "./Filter/Filter";
import GrpCard from "./GrpCard/GrpCard";
import TvReacSkeleton from "./TvReachSkeleton/TvReacSkeleton";
import InsightBlankState from "../../containers/InsightBlankState/InsightBlankState";
import LoadingModal from "./LoadingModal/LoadingModal";
import { useDisclosure } from "@mantine/hooks";
import { numberFormatter } from "@/Util/utils";
import UnauthorizedPage from "@/features/common/UnauthorizedPage";

const TVDashboard = () => {
  const dispatch = useDispatch();
  const targetRef: any = useRef();
  const [fetchTvReachActiveHouseHoldCount] =
    useLazyGetActiveHouseHoldsCountQuery();
  const [fetchCustomerSpotDetails] = useLazyGetCustomerSpotDetailsQuery();
  const [fetchMediaCost] = useLazyGetEmetriqMediaCostForSpotsQuery();

  const [value, setValue] = useState<[Date | any, Date | any]>([null, null]);
  const startDate: any = moment(value[0], "DD-MM-YYYY").format("DD MMM YYYY");
  const endDate: any = moment(value[1], "DD-MM-YYYY").format("DD MMM YYYY");
  const [flag, setFlag] = useState(true);
  const [data, setData] = useState([]);
  const [appImage, setAppImage] = useState(Frame);
  const initialApp: any = useSelector(selectedInitialAppArray);
  const image: any = useSelector(selectImageArr);
  const spotarr = useSelector(selectedSportArray);
  const [appValue, setAppValue] = useState<string | null>(null);
  const [fecthNetEmetriqreach] = useLazyGetEmetriqNetReachReportQuery();
  const [fetchGrpOts] = useLazyGetEmetriqGrpOtsReportQuery();
  const [spotId, setSpotId] = useState<string[] | null>([]);
  const [sport, setsportArray] = useState([]);
  const arr = useSelector(selectspotIdArr);
  const [spotData, setSpotData] = useState([]);
  const [grp, setGrp] = useState<any>(0);
  const [ots, setOts] = useState<any>(0);
  const [totalGRP, setTotalGRP] = useState<any>(0);
  const [netReachPercent, setNetReachPercent] = useState<any>(0);
  const [mediaCostPerNetReach, setMediaCostPerNetReach] = useState<any>(0);
  const [mediaCostPerKontakt, setMediaCostPerKontakt] = useState<any>(0);
  const [mediaCostPerGRP, setMediaCostPerGRP] = useState<any>(0);
  const [totalNetReach, setTotalNetReach] = useState<any>(0);
  const [totalMediaCost, setTotalMediaCost] = useState<any>(0);
  const [totalKontakt, setTotalKontakt] = useState<any>(0);
  const [kontaktklasseVerteilung, setKontaktklasseVerteilung] = useState<any>(
    []
  );
  const [activeHouseholdsCount, setActiveUserCount] = useState<any>(0);
  const [idToken, setIdToken] = useState("");
  const [showSkeleton, setSkeleton] = useState(true);
  const [openedModal, { open, close }] = useDisclosure(false);
  const [filteredSpotIds, setFilteredSpotIds] = useState([]);
  const [showBlackState, setShowBlackState] = useState(true);
  const [searchTermCat, setSearchTermCat] = useState("");
  const [isSelectAll, setIsSelectAll] = useState(false);
  const [selectedSpotIds, setSelectedSpotIds] = useState([]);
  const [isPDFDisabled, setIsPDFDisabled] = useState(true);

  const fetchData = async (
    spotData: any,
    mediaCostData: any,
    grpOtsData: any
  ) => {
    if (
      spotData !== undefined &&
      spotData.length > 0 &&
      mediaCostData.length > 0
    ) {
      const kontaktKlasseLabelMap: any = {
        kk0: "KK0",
        kk14: "KK1-4",
        kk57: "KK5-7",
        kk810: "KK8-10",
        kk11plus: "KK11+",
      };

      const latestData = spotData[spotData.length - 1];
      const updatedVerteilung: any = Object.keys(kontaktKlasseLabelMap).map(
        (key) => ({
          label: kontaktKlasseLabelMap[key],
          value: (latestData[key] / activeHouseholdsCount) * 100,
        })
      );
      setKontaktklasseVerteilung(updatedVerteilung);

      const updatedSpotData = await Promise.all(
        spotData.map((e: any) => {
          let newE = { ...e };
          if (!e.calculated) {
            newE.absoluteNetReach = e.netReach;
            newE.netReach = Number.parseFloat(
              (newE.absoluteNetReach / (activeHouseholdsCount / 100)).toFixed(2)
            );
            newE.mediaCost = Math.floor(
              mediaCostData.find((m: any) => m.date === e.date)?.mediaCost
            );
          }
          return newE;
        })
      );

      let totalGrp = 0;
      grpOtsData.forEach((e: any) => {
        totalGrp += e.mgrp;
      });
      setTotalGRP(totalGrp);
      await renderNetReachMediaCostData(updatedSpotData, mediaCostData);
      await renderGrpOtsData(grpOtsData);
      await renderMediaCostPerGRP();
    }
  };

  const numberFormat = (number: any, separator: any, decimal: any) => {
    number = Number.parseFloat(number);
    return commaNumber(number, separator, decimal);
  };

  const renderGrpOtsData = async (data: any) => {
    let countSpotsTotal = 0;
    data?.forEach((e: any) => {
      countSpotsTotal += e.countSpots;
    });
    if (netReachPercent !== 0) {
      const maxNetReach =
        (netReachPercent / 100) * parseInt(activeHouseholdsCount);
      const ots: any = countSpotsTotal / maxNetReach;
      const grp = netReachPercent * ots;
      setOts(ots?.toFixed(2));
      setGrp(grp?.toFixed(2));
    }
  };

  const renderMediaCostPerGRP = () => {
    if (totalGRP > 0 && totalMediaCost > 0) {
      const mediaCostPerGRP = totalMediaCost / totalGRP;
      setMediaCostPerGRP(mediaCostPerGRP?.toFixed(2));
    }
  };

  const renderNetReachMediaCostData = async (
    spotData: any,
    mediaCostData: any
  ) => {
    let maxNetreach = 0;
    let totalNetReach = 0;
    let totalKontakt = 0;

    spotData?.forEach((element: any) => {
      maxNetreach =
        element.netReach > maxNetreach ? element.netReach : maxNetreach;
      totalNetReach += element.absoluteNetReach;
      totalKontakt +=
        element.kk14 + element.kk57 + element.kk810 + element.kk11plus;
    });

    let totalMediaCost = 0;
    mediaCostData?.forEach((element: any) => {
      totalMediaCost += Number.parseFloat(element.mediaCost);
    });
    let netReachPercentage = maxNetreach.toFixed(2);
    let mediaCostPerNetReach = (totalMediaCost / totalNetReach).toFixed(2);
    let mediaCostPerKontakt = (totalMediaCost / totalKontakt).toFixed(2);
    setSpotData(spotData);
    setTotalNetReach(totalNetReach);
    setTotalKontakt(totalKontakt);
    setTotalMediaCost(totalMediaCost);
    setNetReachPercent(netReachPercentage);
    setMediaCostPerNetReach(mediaCostPerNetReach);
    setMediaCostPerKontakt(mediaCostPerKontakt);
  };

  const fetchAppList = async (sessionStorageData: any) => {
    let parsedLoginData: string | any = null;
    if (sessionStorageData) {
      try {
        parsedLoginData = JSON.parse(sessionStorageData);
        setIdToken(parsedLoginData);
      } catch (error) {
        notifications.show({
          id: "error",
          withCloseButton: true,
          onClose: () => {},
          onOpen: () => {},
          autoClose: 5000,
          title: "ERROR",
          message: JSON.stringify(error),
          color: "red",
          className: "my-notification-class",
          style: { backgroundColor: "white" },
          sx: { backgroundColor: "red" },
          loading: false,
        });
      }
    }
    if (parsedLoginData) {
      const promises: any = [
        fetchTvReachActiveHouseHoldCount({
          tvApiAccessToken: {
            idToken: `Bearer ${parsedLoginData}`,
          },
        }),
        fetchCustomerSpotDetails({
          tvApiAccessToken: {
            idToken: `Bearer ${parsedLoginData}`,
          },
        }),
      ];
      const results = await Promise.allSettled(promises);
      results.forEach((result) => {
        if (result?.status === "fulfilled" && result?.value?.data) {
          const { data } = result.value;
          if (data?.getActiveHouseHoldsCount?.activeHouseholdsCount) {
            setActiveUserCount(
              parseInt(data?.getActiveHouseHoldsCount?.activeHouseholdsCount)
            );
          } else if (data.getCustomerSpotDetails) {
            const formattedData = data?.getCustomerSpotDetails?.map(
              (item: any) => ({
                value: item.customer,
                label: item.customer,
                extraprops: item.spots,
              })
            );
            dispatch(setInitialAppArray(formattedData));
            setData(formattedData);
            setSkeleton(false);
          }
        } else {
          notifications.show({
            id: "error",
            withCloseButton: true,
            onClose: () => {},
            onOpen: () => {},
            autoClose: 5000,
            title: "ERROR",
            message: JSON.stringify(result),
            color: "red",
            className: "my-notification-class",
            style: { backgroundColor: "white" },
            sx: { backgroundColor: "red" },
            loading: false,
          });
        }
      });
    }
  };

  useEffect(() => {
    const sessionStorageData = sessionStorage.getItem("emetriqLogin");
    fetchAppList(sessionStorageData);
    dispatch(setRouteFlag(false));
  }, [sessionStorage, flag]);

  useEffect(() => {
    if (appValue && initialApp?.length) {
      const sportarr: any = initialApp.filter((item: any) => {
        if (item.value === appValue) {
          return item;
        }
      })[0];
      const sport = sportarr?.extraprops.map((item: any) => {
        return { value: item.spot, label: item.spot, props: item?.spotIds };
      });
      setsportArray(sport);
      setFilteredSpotIds(sport);
      setSelectedSpotIds([]);
      setValue([null, null]);
      setIsSelectAll(false);
    }
  }, [initialApp, appValue, image]);

  const handleNetReachAndMediaCostData = async () => {
    setSkeleton(true);
    setShowBlackState(false);
    const data: any = initialApp?.filter((item: any) => {
      return item.value == appValue;
    })[0];
    let getSpotId: any;
    if (spotId !== null && spotId?.length > 0) {
      getSpotId = data?.extraprops
        ?.map((item: any) => {
          if (spotId?.includes(item?.spot) === true) {
            return item?.spotIds;
          }
        })
        .filter(Boolean);
    } else {
      getSpotId = data?.extraprops
        ?.map((item: any) => {
          {
            return parseInt(item?.spotIds);
          }
        })
        .filter(Boolean);
    }
    const arr = getSpotId.flatMap((e: any) => {
      if (typeof e !== "number") {
        return e?.filter((f: any) => f !== undefined);
      } else {
        return e;
      }
    });
    const numArray = arr.map(function (x: any) {
      return x;
    });
    const start =
      moment(startDate, "DD MMM YYYY").format("YYYY-MM-DD") + "T00:00:00.000Z";
    const end =
      moment(endDate, "DD MMM YYYY").format("YYYY-MM-DD") + "T00:00:00.000Z";
    const obj = {
      tvApiAccessToken: {
        idToken: `Bearer ${idToken}`,
        spotids: [...numArray],
        start: start,
        end: end,
        numUsers: activeHouseholdsCount,
      },
    };

    try {
      setTimeout(() => {
        open();
      }, 3000);
      const spotData = await fecthNetEmetriqreach({ ...obj }).unwrap();
      const mediaCostData = await fetchMediaCost({
        tvApiAccessToken: {
          idToken: `Bearer ${idToken}`,
          spotids: [...numArray],
          start,
          end,
        },
      }).unwrap();
      const grpOtpData = await fetchGrpOts({
        tvApiAccessToken: {
          idToken: `Bearer ${idToken}`,
          spotids: [...numArray],
          start,
          end,
          watchedThreshold: 0.8,
        },
      }).unwrap();
      if (spotData !== undefined) {
        fetchData(
          spotData,
          mediaCostData?.getEmetriqMediaCostForSpots,
          grpOtpData
        );
        setSkeleton(false);
        setShowBlackState(false);
        close();
        setIsPDFDisabled(false);
      }
    } catch (e) {
      notifications.show({
        id: "netreach-report-failure",
        withCloseButton: true,
        autoClose: 5000,
        title: "ERROR",
        message:
          "Currently We are facing some issue to fetch the data Please try after sometime.",
        color: "red",
        className: "my-notification-class",
        style: { backgroundColor: "white" },
        sx: { backgroundColor: "red" },
        loading: false,
      });
      setSkeleton(false);
      setShowBlackState(true);
      close();
      throw e;
    }
  };

  const handleSearchChangeCat = (e: any) => {
    setSearchTermCat(e.target.value);
  };

  const handleSelectAll = () => {
    setIsSelectAll(!isSelectAll);
    const arr: any = filteredSpotIds.map((item: any) => item.value);
    setSelectedSpotIds(arr);
    if (isSelectAll) {
      setSelectedSpotIds([]);
    }
  };

  const clearAll = () => {
    setSelectedSpotIds([]);
    setIsSelectAll(false);
  };

  const handleFilteredSpotIds = (e: any) => {
    setSelectedSpotIds(e);
    if (filteredSpotIds?.length == e.length) {
      setIsSelectAll(true);
    } else {
      setIsSelectAll(false);
    }
  };

  useEffect(() => {
    if (searchTermCat !== "") {
      let filterData = sport.filter(
        (item: any) =>
          item?.value
            ?.replace(/ +/g, "")
            ?.toLowerCase()
            ?.includes(searchTermCat.toLowerCase())
      );
      setFilteredSpotIds(filterData);
    }
  }, [searchTermCat]);

  const handlePDFClick = () => {
    generatePDF(targetRef, {
      filename: `TV_Insights_Report_${appValue}_[${startDate}-${endDate}].pdf`,
    });
  };

  return (
    <>
      {sessionStorage.getItem("tvInsightTab") == "true" ? (
        <>
          <LoadingModal opened={openedModal} onClose={close} />
          <>
            <Grid className="ma-tv-dashboard  tv-dashboard" ref={targetRef}>
              <Grid.Col span={12}>
                <Card shadow="sm" radius="md" className="mb-4">
                  <Filter
                    appImage={appImage}
                    data={data}
                    appValue={appValue}
                    handleAppSelection={(e: any) => setAppValue(e)}
                    itemComponent={SelectItem}
                    spotId={spotId}
                    sport={sport?.length > 0 ? sport : []}
                    handleSpotId={(e: any) => setSpotId(e)}
                    value={value}
                    setValue={(e: any) => setValue(e)}
                    startDate={startDate}
                    endDate={endDate}
                    handleNetReachAndMediaCostData={
                      handleNetReachAndMediaCostData
                    }
                    filteredSpotIds={filteredSpotIds}
                    searchTermCat={searchTermCat}
                    handleSearchChangeCat={handleSearchChangeCat}
                    handleSelectAll={handleSelectAll}
                    isSelectAll={isSelectAll}
                    selectedSpotIds={selectedSpotIds}
                    handleClick={handleFilteredSpotIds}
                    clearAll={clearAll}
                    handlePDFClick={handlePDFClick}
                    isPDFdisabled={isPDFDisabled}
                  />
                </Card>
                {showBlackState === true && (
                  <InsightBlankState
                    title={"Generate Detailed Tv Insights!"}
                    text={
                      "Start with selecting an customer, to get details insights"
                    }
                  />
                )}
                <>
                  {showBlackState === false && showSkeleton === true && (
                    <TvReacSkeleton />
                  )}
                </>
                {showBlackState === false && (
                  <>
                    <Card shadow="sm" radius="md">
                      <Card.Section className="p-4 center-section">
                        <Grid>
                          <GrpCard
                            grp={numberFormat(grp, ".", ",")}
                            ots={numberFormat(ots, ".", ",")}
                            netReachPercent={numberFormat(
                              netReachPercent,
                              ".",
                              ","
                            )}
                            mediaCostPerGRP={
                              numberFormat(mediaCostPerGRP, ".", ",") + " €"
                            }
                            totalMediaCost={
                              numberFormat(
                                totalMediaCost?.toFixed(2),
                                ".",
                                ","
                              ) + " €"
                            }
                            totalKontakt={numberFormat(totalKontakt, ".", ",")}
                            activeHouseholdsCount={numberFormat(
                              activeHouseholdsCount,
                              ".",
                              ","
                            )}
                          />
                        </Grid>
                        <Divider my="sm" />
                        <Grid>
                          <Grid.Col span={6}>
                            <Box
                              sx={(theme) => ({
                                textAlign: "center",
                                padding: theme.spacing.xl,
                                borderRadius: theme.radius.sm,
                                cursor: "pointer",
                                border: "1px solid rgba(198, 208, 224, 1)",
                                height: "400px",
                              })}
                            >
                              <Flex>
                                <Text fz="md" fw={500}>
                                  Media Spending & Net Reach
                                </Text>
                                <Tooltip
                                  label="Media Spend & Net Reach: Shows the Gross Media Spend over time and reached Household in %"
                                  withArrow
                                  openDelay={500}
                                  closeDelay={100}
                                >
                                  <InformationCircleIcon className="h-6 w-6 cursor-pointer details-icon" />
                                </Tooltip>
                              </Flex>
                              <LineBarChart
                                height="350px"
                                data={spotData}
                                chartName="Media Spending & Nettoreichweite"
                                xField="date"
                                yPrefix="€"
                                barLabels={[["Media Spending", "mediaCost"]]}
                                lineLabels={[["Net Reach", "netReach"]]}
                              />
                            </Box>
                          </Grid.Col>
                          <Grid.Col span={6}>
                            <Box
                              sx={(theme) => ({
                                textAlign: "center",
                                padding: theme.spacing.xl,
                                borderRadius: theme.radius.sm,
                                cursor: "pointer",
                                border: "1px solid rgba(198, 208, 224, 1)",
                                height: "400px",
                              })}
                            >
                              <Flex>
                                <Text fz="md" fw={500}>
                                  Contact Class
                                </Text>
                                <Tooltip
                                  label="Contact Class: Shows the number of households within specific contact classes"
                                  withArrow
                                  openDelay={500}
                                  closeDelay={100}
                                >
                                  <InformationCircleIcon className="h-6 w-6 cursor-pointer details-icon" />
                                </Tooltip>
                              </Flex>
                              <BarChart
                                data={kontaktklasseVerteilung}
                                height="350px"
                                chartName=""
                                colors={[
                                  "#e7ff1d",
                                  "#22abde",
                                  "#02748b",
                                  "#22abde",
                                  "#568a9e",
                                ]}
                              />
                            </Box>
                          </Grid.Col>
                        </Grid>
                      </Card.Section>
                    </Card>
                  </>
                )}
              </Grid.Col>
            </Grid>
          </>
        </>
      ) : (
        <UnauthorizedPage />
      )}
    </>
  );
};

export default Hoc(TVDashboard);
